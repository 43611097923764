export const COUNTRY_SELECT_OPTIONS = {
    gb: 'GB',
    ie: 'IE',
    ci: 'CI',
    gg: 'GG',
    fr: 'FR',
    de: 'DE',
    im: 'IM',
    nir: 'NIR',
    roi: 'ROI',
    it: 'IT',
    esp: 'ESP',
    pl: 'PL',
    ro: 'RO',
    sk: 'SK',
};

export const REGION_OPTIONS = {
    gb: 'UK',
    ie: 'IE',
    ci: 'CI',
    gg: 'GG',
    fr: 'FR',
    de: 'DE',
    im: 'IM',
    nir: 'NIR',
    roi: 'ROI',
    it: 'IT',
    esp: 'ESP',
    pl: 'PL',
    ro: 'RO',
    sk: 'SK',
};

export const FORM_ERROR_TOOLTIP = {
    timeOutInterval: 4000,
    errorMessage: 'There are incomplete required fields',
};

export const BUTTON_DELAY_TIME: number = 150;
export const EXPANSION_DELAY_TIME: number = 350;

export const TOOLTIP_FIRST_NAME_TOOLTIP = 'Your first name as it appears on official documents. Please do not use abbreviations or nicknames.';
export const TOOLTIP_LAST_NAME_TOOLTIP = 'Your surname as it appears on official documents.';
export const TOOLTIP_BIRTH_DATE_TOOLTIP = 'You must be over 18 to become an Independent MeAmora Stylist.';
export const TOOLTIP_NAME_OF_ACCOUNT_TOOLTIP = 'We ask for bank details so that we can pay commissions directly and securely into your account.';
export const TOOLTIP_SHIPPING_ADDRESS_NAME = 'The Address Name is what customers will see in their Pop-Up order - we will not share the actual delivery address publicly.';

export const BREAKAWAY_POWER = 40;
export const MIN_PV = 150;

export const STOPLIGHT_STATUS_TYPE_INSUFFICIENT = 'insufficient';
export const STOPLIGHT_STATUS_TYPE_ALMOST = 'almost';
export const STOPLIGHT_STATUS_TYPE_FULFILLED = 'fulfilled';

export const JOAH_FLAG_KEY = 'hide_joah_products';
