import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { MILESTONE_KEY, MILESTONE_TYPE, STEP_UP_MILESTONE_KEY } from '@/modules/Dashboard/constants';
import dateManager from '@/utils/time';
import { MilestoneManagerPowerSellerRequirementsLevelsType, MilestoneManagerPowerSellerRequirementsLevelType, MilestoneManagerPowerSellerRequirementType } from './types';
import { defaultPowerSellerRequirements } from './defaults';

export default class MilestoneManager {
    static readonly milestoneMetricConstants = {
        stepUp1: MILESTONE_KEY.stepUp1,
        stepUp2: MILESTONE_KEY.stepUp2,
        stepUp3: MILESTONE_KEY.stepUp3,
        numberOfNewStylists: STEP_UP_MILESTONE_KEY.numberOfNewStylists,
        numberOfNewQualifiedStylists: STEP_UP_MILESTONE_KEY.numberOfNewQualifiedStylists,
        stepUpEarnings: STEP_UP_MILESTONE_KEY.stepUpEarnings,
    }

    private static calculateDaysLeft(from: string, to: string): string {
        const left = dateManager.getDifference(from, to, 'd');

        if (left > 0) {
            return `${left} ${left > 1 ? 'days' : 'day'}`;
        }

        return 'less than a day';
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getMilestone(val: string, amount: number = 0) {
        switch (val) {
        case MilestoneManager.milestoneMetricConstants.stepUp1: {
            return {
                title: 'Step 1',
                withValue: false,
                required: 300,
            };
        }
        case MilestoneManager.milestoneMetricConstants.stepUp2: {
            return {
                title: 'Step 2',
                withValue: false,
                required: 300,
            };
        }
        case MilestoneManager.milestoneMetricConstants.stepUp3: {
            return {
                title: 'Bonus step',
                withValue: false,
                required: 900,
            };
        }
        case MilestoneManager.milestoneMetricConstants.numberOfNewStylists: {
            return {
                title: 'Numbers of New Enrolled Stylists',
                withValue: false,
                required: 0,
            };
        }
        case MilestoneManager.milestoneMetricConstants.numberOfNewQualifiedStylists: {
            return {
                title: 'Numbers of New Qualified Enrolled Stylists',
                withValue: false,
                required: 0,
            };
        }
        case MilestoneManager.milestoneMetricConstants.stepUpEarnings: {
            return {
                title: 'Step up earnings',
                withValue: false,
                required: 0,
            };
        }
        default: {
            return {
                title: '',
                withValue: false,
                required: 0,
            };
        }
        }
    }

    public static resolveMilestoneLabel(key) {
        return MilestoneManager.getMilestone(key).title;
    }

    public static resolveMilestoneRequired(key) {
        return MilestoneManager.getMilestone(key).required;
    }

    public static resolveMilestoneValue(milestone: MilestoneType, key: string): number {
        const val = milestone.state.find((item) => item.metric === key);
        const isInCents = [
            MilestoneManager.milestoneMetricConstants.stepUp1,
            MilestoneManager.milestoneMetricConstants.stepUp2,
            MilestoneManager.milestoneMetricConstants.stepUp3,
        ].includes(milestone.key);

        if (isInCents) {
            return val ? Number(val.value) / 100 : 0;
        }

        return val ? Number(val.value) : 0;
    }

    public static resolveHelperText(milestone: MilestoneType, currentDate) {
        if (dateManager.isBefore(currentDate, milestone.startDate)) {
            return `Starts in: ${this.calculateDaysLeft(currentDate, milestone.startDate)}`;
        } if (dateManager.isBefore(currentDate, milestone.endDate)) {
            return `Time left to complete: ${this.calculateDaysLeft(currentDate, milestone.endDate)}`;
        }
        return 'Period has ended';
    }

    public static progressValue(current, required): number {
        const x = current * 100;

        return x / Number(required);
    }

    public static resolvePowerSellerRequirements(type: string, val: string):
    MilestoneManagerPowerSellerRequirementsLevelType {
        const value = Number(val) / 100;
        const levelConfig = this.getPowerSellerLevelsConfig();
        const { monthly, quarterly, yearly } = levelConfig;

        switch (type) {
        case MILESTONE_TYPE.powerSellerMonthly:
            return {
                bronze: this.formatPowerSellerRequirement(
                    monthly.bronze,
                    value,
                    monthly.bronze.required,
                ),
                silver: this.formatPowerSellerRequirement(
                    monthly.silver,
                    value,
                    monthly.silver.required,
                ),
                gold: this.formatPowerSellerRequirement(
                    monthly.gold,
                    value,
                    monthly.gold.required,
                ),
            };
        case MILESTONE_TYPE.powerSellerQuarterly:
            return {
                bronze: this.formatPowerSellerRequirement(
                    quarterly.bronze,
                    value,
                    quarterly.bronze.required,
                ),
                silver: this.formatPowerSellerRequirement(
                    quarterly.silver,
                    value,
                    quarterly.silver.required,
                ),
                gold: this.formatPowerSellerRequirement(
                    quarterly.gold,
                    value,
                    quarterly.gold.required,
                ),
            };
        case MILESTONE_TYPE.powerSellerAnnual:
            return {
                bronze: this.formatPowerSellerRequirement(
                    yearly.bronze,
                    value,
                    yearly.bronze.required,
                ),
                silver: this.formatPowerSellerRequirement(
                    yearly.silver,
                    value,
                    yearly.silver.required,
                ),
                gold: this.formatPowerSellerRequirement(
                    yearly.gold,
                    value,
                    yearly.gold.required,
                ),
            };

        default:
            return defaultPowerSellerRequirements();
        }
    }

    private static formatPowerSellerRequirement(val: MilestoneManagerPowerSellerRequirementType,
        value: number, required: number) {
        let formattedValue = 0;

        if (value > 0) {
            formattedValue = value;
        }

        return {
            required: val.required,
            current: this.progressValue(formattedValue, required),
            label: val.label,
        };
    }

    public static getPowerSellerLevelsConfig(): MilestoneManagerPowerSellerRequirementsLevelsType {
        const bronzeLabel = 'Bronze';
        const silverLabel = 'Silver';
        const goldLabel = 'Gold';

        return {
            yearly: {
                bronze: {
                    label: bronzeLabel,
                    required: 3600,
                    current: 0,
                },
                silver: {
                    label: silverLabel,
                    required: 9000,
                    current: 0,
                },
                gold: {
                    label: goldLabel,
                    required: 18000,
                    current: 0,
                },
            },
            quarterly: {
                bronze: {
                    label: bronzeLabel,
                    required: 900,
                    current: 0,
                },
                silver: {
                    label: silverLabel,
                    required: 2250,
                    current: 0,
                },
                gold: {
                    label: goldLabel,
                    required: 4500,
                    current: 0,
                },
            },
            monthly: {
                bronze: {
                    label: bronzeLabel,
                    required: 300,
                    current: 0,
                },
                silver: {
                    label: silverLabel,
                    required: 750,
                    current: 0,
                },
                gold: {
                    label: goldLabel,
                    required: 1500,
                    current: 0,
                },
            },
        };
    }
}
