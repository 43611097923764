import { PowerSellerClubDisplayItemType } from './types';

export const defaultPowerSellerDisplayItem: () =>
    PowerSellerClubDisplayItemType = () => ({
        dateTo: '',
        label: '',
        progress: 0,
        color: '',
        value: '0.00',
    });
