
import { RankType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class LeaderLegsStatusTooltipContent extends Vue {
    @Prop() private taRank!: RankType;

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get ps(): string {
        if (this.taRank.requirements === null) {
            return '';
        }

        const metric = this.taRank.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.pv);

        return metric ? metric.value : '';
    }

    get ov(): string {
        if (this.taRank.requirements === null) {
            return '';
        }

        const metric = this.taRank.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.ov);

        return metric ? metric.value : '';
    }
}
