
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import profile from '@/modules/Profile';
import profilePicture from '@/modules/Profile/profilePicture';
import countries from '@/modules/Countries';
import env from '@/environment';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import PersonalDetails from '@/projectComponents/personalDetails/index.vue';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { resolveBankingDetails } from '@/modules/labels';
import { NATIONAL_NUMBER_MESSAGE, BIRTHDATE_MESSAGE } from '@/utils/messages/formValidation';
import { FlagFullType, ProfilePictureType, ProfileType } from '@/api/graphQL/graphNodes/types';
import auth from '@/modules/Auth';
import { CountriesType } from '@/modules/Event/types';
import BusinessDetails from './BusinessDetails/index.vue';
import Camera from './Camera/index.vue';
import UploadDialog from './UploadDialog/index.vue';

@Component({
    components: {
        FormErrorTooltip,
        PersonalDetails,
        BusinessDetails,
        Camera,
        UploadDialog,
    },
})
export default class ProfileContent extends Vue {
    @Get(auth) private suspended!: boolean;

    @Get(profile, 'data') private formData!: ProfileType;

    @Get(profile) private profilePicture!: null|ProfilePictureType;

    @Get(profilePicture) private image!: string;

    @Get(profile) private loading!: boolean;

    @Get(profile) private formErrors!: ErrorType;

    @Get(profilePicture) private isShotPhoto!: boolean;

    @Get(profile) private displayTooltip!: boolean;

    @Get(profile) private joah!: FlagFullType;

    @Get(countries) private countries!: CountriesType[];

    get gbCountry(): string {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get imgUrl(): string {
        return `${this.awsProfile ? '' : this.serverUrl}${this.link}`;
    }

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get awsProfile(): boolean {
        if (this.profilePicture !== null) {
            const provider = this.profilePicture.context.find((item) => item.metric === 'provider');
            if (provider && provider.value === 'aws_profile') {
                return true;
            }
        }
        return false;
    }

    get link(): string {
        return this.profilePicture ? this.profilePicture.link : '';
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    async handleProfileUpdate() {
        await profile.updateProfile();
    }

    getHelperText(val: string) {
        if (val === 'birthDate') {
            return BIRTHDATE_MESSAGE;
        }
        return NATIONAL_NUMBER_MESSAGE;
    }

    handleImageUploadModalOpen() {
        profile.setImageDialogOpen(true);
    }

    handleCameraModalOpen() {
        profile.setCameraDialogOpen(true);
    }

    @Watch('formData.country')
    handleCountryChange(val: string) {
        profile.setLabels(resolveBankingDetails(val).labels);
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.formData);

        profile.removeFormError(key);
        profile.setFormData(res);
    }

    handleJoahChange(value) {
        profile.setJoah({ ...this.joah, value: value === 'true' });
    }
}
