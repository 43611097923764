import { EVENT_TYPE } from '@/modules/Calendar/constants';
// import FunctionalityManager from '@/utils/functionality-manager';
import { MILESTONE_TYPE } from '@/modules/Dashboard/constants';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { DashboardType } from './types';

export default class GetDashboardQuery extends
    AbstractQueryResource<GetDashboardResultType> {
    protected getQuery(): string {
        // const withClub250: boolean = FunctionalityManager.shouldDisplay('club250');
        // const runTotalsForPeriod: string = withClub250 ?
        //  `runTotalsForPeriod(limit: 12, from: $firstDayOfYear) {
        //     id,
        //     runId,
        //     enrolleeMetrics {
        //         pv
        //     },
        //     from,
        //     to
        // }` : '';

        const runTotalsForPeriod: string = '';

        // const achievementAward = `dashboardAchievementAwards {
        //     type,
        //     title,
        //     tooltip,
        //     description,
        //     leaderboard {
        //         position,
        //         previousPosition,
        //         enrolleeId,
        //         name,
        //         metricValue,
        //     }
        // },`

        const achievementAward = '';

        // const milestones = `nextSeason: milestones(filter: { period: 0 }) {
        //     type,
        //     category,
        //     key,
        //     achieved,
        //     achievedAt,
        //     unachievable,
        //     state {
        //         metric,
        //         value,
        //     },
        // },
        // thisSeason: milestones(filter: { period: 1, type: "bdp" }) {
        //     type,
        //     category,
        //     key,
        //     achieved,
        //     achievedAt,
        //     unachievable,
        //     state {
        //         metric,
        //         value,
        //     },
        // },`;

        const milestones = `stepsToSuccess: milestones(filter: { period: 0, type: "${MILESTONE_TYPE.stepChild}" }) {
            type,
            category,
            key,
            achieved,
            achievedAt,
            unachievable,
            startDate,
            endDate,
            state {
                metric,
                value,
            },
        },
        stepUp: milestones(filter: { period: 0, type: "${MILESTONE_TYPE.stepParent}" }) {
            type,
            category,
            key,
            achieved,
            achievedAt,
            unachievable,
            startDate,
            endDate,
            state {
                metric,
                value,
            },
        },`;

        // return `query ${this.getName()}($dateFrom: DateTime!,
        //  $dateTo: DateTime!, $runsDateFrom: String, $lastMonthDateFrom:
        //  DateTime!, $lastMonthDateTo: DateTime!${withClub250
        // ? ', $firstDayOfYear: DateTime!' : ''}) {
        return `query ${this.getName()}($dateFrom: DateTime!, $lastMonthDateFrom: DateTime!, $lastMonthDateTo: DateTime!) {
            profile {
                personalRecruitsCount,
                rank {
                    id,
                    label,
                    power,
                    requirements {
                        metric,
                        value
                    },
                    titleRequirements {
                        metric,
                        value
                    }
                },
                newAmbassadorsPersonal: newStartersCountCommissionEngine(date: $dateFrom, type: "personal"),
                newAmbassadorsOrganizational: newStartersCountCommissionEngine(date: $dateFrom, type: "organisational"),
                organizationCount,
                activeOnFileDirectEnrollees,
                ${milestones}
                participatedRuns(includeUnlocked: true, filterDateFrom: "2024-08-04 00:00") {
                    id,
                    label,
                    from,
                    to
                },
                ${runTotalsForPeriod}
            },
            ${achievementAward}
            thisMonth: eventsTotals(type: "${EVENT_TYPE.organisational_and_personal}") {
                finishedEvents,
                bookedEvents,
                avgSalesPerEvent,
                totalSales
            },
            lastMonth: eventsTotals(dateFrom: $lastMonthDateFrom, dateTo: $lastMonthDateTo,
             type: "${EVENT_TYPE.organisational_and_personal}") {
                finishedEvents,
            },
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getDashboard';
    }
}

export type GetDashboardResultType = {
    profile: DashboardType;
    // dashboardAchievementAwards: AchievementAwardType[];
    thisMonth: {
        finishedEvents: number;
        bookedEvents: number;
        avgSalesPerEvent: string;
        totalSales: number;
    };
    lastMonth: {
        finishedEvents: number;
    };
}

export type GetDashboardParamsType = {
    dateFrom: string;
    lastMonthDateFrom: string;
    lastMonthDateTo: string;
    // firstDayOfYear: string;
}
