
import { Vue, Component } from 'vue-property-decorator';
import newStarters from '@/modules/NewStarters';
import rank from '@/modules/Rank';
import { Get } from '@/utils/vuex-module-mutators';
import LegItem from '@/projectComponents/enrolleeLegItem/Item/index.vue';
import {
    MilestoneType, NewStartersType, RankRequirementsType, RankType,
} from '@/api/graphQL/graphNodes/types';
import system from '@/modules/System';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import Achievement from '@/projectComponents/achievement/index.vue';
import resolveStatus from '@/projectComponents/achievement/resolveStatus';
import nextRankResolver from '@/modules/Rank/services/nextRankResolver';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementData } from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/types';
import StepUp from '@/projectComponents/stepUp/index.vue';
import { defaultMilestone } from '@/modules/Dashboard/defaults';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import Requirement from './Requirement/index.vue';
import StepsToSuccess from './StepsToSuccess/index.vue';

@Component({
    components: {
        LegItem,
        Requirement,
        Achievement,
        StepsToSuccess,
        StepUp,
    },
})
export default class NewStartersContent extends Vue {
    @Get(newStarters) starters!: NewStartersType[];

    @Get(newStarters) private searchedLegs!: NewStartersType[];

    @Get(newStarters) loading!: boolean;

    @Get(newStarters) loadingInBackground!: boolean;

    @Get(rank) private ranks!: RankType[];

    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(newStarters) private expandedNewStarters!: string[];

    @Get(newStarters) private activeSponsorDetailsModal!: string;

    @Get(newStarters) private displaySearchResults!: boolean;

    @Get(newStarters) private offset!: number;

    @Get(newStarters) private limit!: number;

    @Get(newStarters) private count!: number;

    @Get(system) private screenType!: string;

    get isMocked() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }

    get currentDate(): string {
        return this.isMocked
            ? mockedDateManager.getCurrentDate(dateManager.getDayTimeFormat())
            : dateManager.getCurrentDateTime(dateManager.getDayTimeFormat());
    }

    get displayLoadMore(): boolean {
        if (this.displaySearchResults) {
            return false;
        }
        return this.limit <= this.count;
    }

    get displayLegs(): NewStartersType[] {
        if (this.displaySearchResults) {
            return this.searchedLegs;
        }
        return this.starters;
    }

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    calculateStepUpMilestone(stepUp: MilestoneType[]): MilestoneType {
        const val = stepUp[0];
        return val || defaultMilestone();
    }

    calculateDisplayStepUp(leg: NewStartersType): boolean {
        const step = this.calculateStepUpMilestone(leg.stepUp);
        if (!step.endDate) {
            return false;
        }

        return dateManager.isBefore(
            this.currentDate,
            dateManager.getDateWithOffset(1, step.endDate, dateManager.getDayTimeFormatUtc(), 'M'),
        );
    }

    calculateRequirements(leg: NewStartersType): RankRequirementData[] {
        let rankRequirements: RankRequirementsType[] = [];
        const nextRank = nextRankResolver(leg.rank.power);

        if (nextRank === null && leg.rank.requirements) {
            rankRequirements = [...leg.rank.requirements];
        } else if (nextRank !== null && nextRank.titleRequirements !== null) {
            rankRequirements = [...nextRank.titleRequirements];
        }

        return rankRequirements.map((item) => {
            const currentStat = leg.stats.find((statItem) => statItem.metric === item.metric);
            const current = currentStat ? currentStat.value : '0.00';

            return {
                label: this.resolveLabel(item.metric),
                current,
                metric: item.metric,
                required: item.value,
            };
        });
    }

    resolveRequirementLabel(leg: NewStartersType): string {
        const nextRank = nextRankResolver(leg.rank.power);
        const label = nextRank ? nextRank.label : leg.rank.label;

        return `Progress To ${label}`;
    }

    status(milestone: MilestoneType): string {
        return resolveStatus(milestone);
    }

    resolveValue(type: string): string {
        const res = WildcardManager.quickStartLabelResolver(type);

        return res.short;
    }

    resolveLabel(metric): string {
        return RankRequirementManager.getRankRequirementLabel(metric).title;
    }

    getNewStartersExpanded(leg: NewStartersType) {
        return !!this.expandedNewStarters.find((item) => item === leg.id);
    }

    handleExpandedContentToggle(val: string) {
        newStarters.toggleExpandedList(val);
    }

    handleSponsorDetailDialog(val: string) {
        newStarters.setActiveSponsorDetailsModal(val);
    }

    handleOpenOrders(id: string) {
        this.$router.push({ name: 'newStylistsOrders', params: { id } });
    }

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        await newStarters.getData({ loadInBackground: true, offset });
        scrollToTop('#app > div.App__Layout > div > div.Scene__Content.Scene__Content--noPadding > div > div.Ui__ExpandableListLayout');
    }
}
