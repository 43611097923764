import { StatsType } from '@/api/graphQL/graphNodes/types';
import { LEXIS_NEXIS_STATUS } from '@/modules/Register/constants';
import rankRequirementManager from '@/utils/rankRequirementManager';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import { StatsDataType, ResolverEnrolleeType } from '../types';
import { enrollee, getEnrolleePersonalRecruits } from './enrollee';

const getEnrollee: (id: string) => ResolverEnrolleeType = (id) => {
    let item: ResolverEnrolleeType = enrollee(id);

    if (!item) {
        item = {
            totals: {
                gv: 0,
                ov: 0,
                pv: 0,
                spa_gv: 0,
            },
            payRankId: '',
            personalRecruitsCount: 0,
            qualifiedBranchesCount: 0,
            ambassadorLegsCount: 0,
            agreedToTermsAndConditions: false,
            centralTeam: [],
            customers: [],
            directDownline: [],
            qualifiedLeaderBranchIds: [],
            email: '',
            id: '',
            joinDate: '',
            kitPurchaseDate: null,
            leaderLegsCount: 0,
            leaderLegs: [],
            uplineLeaderId: null,
            organisational: [],
            paidForKit: false,
            parentId: '',
            personal: [],
            phoneNumber: '',
            secondPhoneNumber: null,
            secondAddress: null,
            country: null,
            region: null,
            county: '',
            town: null,
            postCode: null,
            address: '',
            rankId: '',
            highestAchievedRankId: '',
            activeOnFileDirectEnrollees: 0,
            risingStars: [],
            additionalCommission: '',
            ctb: '',
            leadershipBonus: '',
            totalSales: '',
            ambassadorsCountsByActivityLevel: null,
            newAmbassadorsPersonal: 0,
            newAmbassadorsInCentralTeam: 0,
            newAmbassadorsOrganizational: 0,
            qualifiedFrontlineLeadersCount: 0,
            starterKit: 0,
            qualifiedOrgLeaderCount: 0,
            vanityUrl: '',
            shopUrl: '',
            customerNumber: '',
            canRegister: true,
            shadow: false,
            firstName: '',
            lastName: '',
            birthDate: '',
            hidden: false,
            bankDetails: {
                bankSortCode: '',
                bankAccountNumber: '',
                bankAccountName: '',
            },
            lexisNexisStatus: LEXIS_NEXIS_STATUS.none,
            aOFStatus: null,
        };
    }

    return item;
};

const getValues: (id: string) => StatsType[] = (id) => [
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.gv,
        value: String(getEnrollee(id).totals!.gv!),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.ov,
        value: String(getEnrollee(id).totals!.ov!),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.pv,
        value: String(getEnrollee(id).totals!.pv!),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.uv,
        value: String(getEnrollee(id).totals!.spa_gv!),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.personalRecruits,
        value: String(getEnrolleePersonalRecruits(id)),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.qualifiedBranches,
        value: String(getEnrollee(id).qualifiedBranchesCount),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.ambassadorLegs,
        value: String(getEnrollee(id).ambassadorLegsCount!),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.qualifiedFrontlineLeaders,
        value: String(getEnrollee(id).qualifiedFrontlineLeadersCount!),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.activeOnFileStylists,
        value: String(getEnrollee(id).activeOnFileDirectEnrollees!),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.leaderLegs,
        value: String(getEnrollee(id).leaderLegs.length!),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.starterKit,
        value: String(getEnrollee(id).starterKit),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.fullMonthsSinceJoin,
        value: String(dateManager.getDifference(getEnrollee(id).joinDate, mockedDateManager.getCurrentDate(), 'month')),
    },
    {
        metric: rankRequirementManager.rankRequirementMetricConstants.uniqueOrderBonusMetric,
        value: String(5),
    },
];

const statsList: StatsDataType[] = [
    {
        id: '0',
        values: getValues('0'),
    },
    {
        id: '1',
        values: getValues('1'),
    },
    {
        id: '2',
        values: getValues('2'),
    },
    {
        id: '3',
        values: getValues('3'),
    },
    {
        id: '4',
        values: getValues('4'),
    },
    {
        id: '5',
        values: getValues('5'),
    },
    {
        id: '6',
        values: getValues('6'),
    },
    {
        id: '7',
        values: getValues('7'),
    },
    {
        id: '8',
        values: getValues('8'),
    },
    {
        id: '9',
        values: getValues('9'),
    },
    {
        id: '10',
        values: getValues('10'),
    },
    {
        id: '11',
        values: getValues('11'),
    },
    {
        id: '12',
        values: getValues('12'),
    },
    {
        id: '13',
        values: getValues('13'),
    },
    {
        id: '14',
        values: getValues('14'),
    },
    {
        id: '15',
        values: getValues('15'),
    },
    {
        id: '16',
        values: getValues('16'),
    },
    {
        id: '17',
        values: getValues('17'),
    },
    {
        id: '18',
        values: getValues('18'),
    },
    {
        id: '19',
        values: getValues('19'),
    },
    {
        id: '20',
        values: getValues('20'),
    },
    {
        id: '21',
        values: getValues('21'),
    },
    {
        id: '22',
        values: getValues('22'),
    },
    {
        id: '23',
        values: getValues('23'),
    },
    {
        id: '24',
        values: getValues('24'),
    },
    {
        id: '25',
        values: getValues('25'),
    },
    {
        id: '26',
        values: getValues('26'),
    },
    {
        id: '27',
        values: getValues('27'),
    },
    {
        id: '28',
        values: getValues('28'),
    },
    {
        id: '29',
        values: getValues('29'),
    },
    {
        id: '30',
        values: getValues('30'),
    },
    {
        id: '31',
        values: getValues('31'),
    },
    {
        id: '32',
        values: getValues('32'),
    },
    {
        id: '33',
        values: getValues('33'),
    },
    {
        id: '34',
        values: getValues('34'),
    },
    {
        id: '35',
        values: getValues('35'),
    },
    {
        id: '36',
        values: getValues('36'),
    },
    {
        id: '36',
        values: getValues('36'),
    },
    {
        id: '37',
        values: getValues('37'),
    },
    {
        id: '38',
        values: getValues('38'),
    },
    {
        id: '39',
        values: getValues('39'),
    },
    {
        id: '40',
        values: getValues('40'),
    },
    {
        id: '41',
        values: getValues('41'),
    },
    {
        id: '42',
        values: getValues('42'),
    },
    {
        id: '43',
        values: getValues('43'),
    },
    {
        id: '44',
        values: getValues('44'),
    },
    {
        id: '45',
        values: getValues('45'),
    },
    {
        id: '46',
        values: getValues('46'),
    },
    {
        id: '47',
        values: getValues('47'),
    },
    {
        id: '48',
        values: getValues('48'),
    },
    {
        id: '49',
        values: getValues('49'),
    },
    {
        id: '50',
        values: getValues('50'),
    },
    {
        id: '51',
        values: getValues('51'),
    },
    {
        id: '52',
        values: getValues('52'),
    },
    {
        id: '53',
        values: getValues('53'),
    },
    {
        id: '54',
        values: getValues('54'),
    },
    {
        id: '55',
        values: getValues('55'),
    },
    {
        id: '56',
        values: getValues('56'),
    },
];

const stats: (id: string) => StatsType[] = (id) => {
    const statItem = statsList.find((item: StatsDataType) => item.id === id);
    if (statItem) {
        return statItem.values;
    }

    return [];
};

export { stats };
