
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import milestoneManager from '@/utils/milestoneManager';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import { MILESTONE_STATE_METRIC } from '@/modules/Dashboard/constants';
import StepUp from '@/projectComponents/stepUp/index.vue';
import { defaultMilestone } from '@/modules/Dashboard/defaults';

@Component({
    components: {
        StepUp,
    },
})
export default class DashboardStepsToSuccess extends Vue {
    @Get(dashboard, 'data.stepsToSuccess') private stepsToSuccess!: MilestoneType[];

    @Get(dashboard, 'data.stepUp') private stepUp!: MilestoneType[];

    get totalKey() {
        return MILESTONE_STATE_METRIC.total;
    }

    get sortedStepsToSuccess(): MilestoneType[] {
        return this.stepsToSuccess.sort((a, b) => (a.type < b.type ? -1 : 1));
    }

    get isMocked() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }

    get currentDate(): string {
        return this.isMocked
            ? mockedDateManager.getCurrentDate(dateManager.getDayTimeFormat())
            : dateManager.getCurrentDateTime(dateManager.getDayTimeFormat());
    }

    get stepUpMilestone(): MilestoneType {
        const val = this.stepUp[0];
        return val || defaultMilestone();
    }

    get displayStepUp(): boolean {
        if (!this.stepUpMilestone.endDate) {
            return false;
        }

        return dateManager.isBefore(
            this.currentDate,
            dateManager.getDateWithOffset(1, this.stepUpMilestone.endDate, dateManager.getDayTimeFormatUtc(), 'M'),
        );
    }

    resolveMilestoneLabel(key) {
        return milestoneManager.resolveMilestoneLabel(key);
    }

    progressValue(step: MilestoneType) {
        return milestoneManager.progressValue(
            this.resolveMilestoneValue(step),
            milestoneManager.resolveMilestoneRequired(step.key),
        );
    }

    resolveHelperText(step: MilestoneType) {
        return milestoneManager.resolveHelperText(step, this.currentDate);
    }

    resolveMilestoneValue(step: MilestoneType) {
        return milestoneManager.resolveMilestoneValue(step, this.totalKey).toFixed(2);
    }

    resolveMilestoneRequired(key) {
        return milestoneManager.resolveMilestoneRequired(key).toFixed(2);
    }
}
