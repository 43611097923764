
import { Vue, Component, Prop } from 'vue-property-decorator';
import MilestoneManager from '@/utils/milestoneManager';
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { defaultMilestone } from '@/modules/Dashboard/defaults';
import { MILESTONE_STATE_METRIC, STEP_UP_MILESTONE_KEY } from '@/modules/Dashboard/constants';

@Component
export default class StepUp extends Vue {
    @Prop() private steps!: MilestoneType[];

    resolveCurrent(milestone: MilestoneType, key: string): number {
        return MilestoneManager.resolveMilestoneValue(milestone, key);
    }

    resolveLabel(key: string): string {
        return MilestoneManager.resolveMilestoneLabel(key);
    }

    get validCountKey() {
        return MILESTONE_STATE_METRIC.validCount;
    }

    get achievedCountKey() {
        return MILESTONE_STATE_METRIC.achievedCount;
    }

    get stepUpMilestone() {
        const step = this.steps[0];

        return step || defaultMilestone();
    }

    get numberOfNewQualifiedStylistsKey() {
        return STEP_UP_MILESTONE_KEY.numberOfNewQualifiedStylists;
    }

    get numberOfNewStylistsKey() {
        return STEP_UP_MILESTONE_KEY.numberOfNewStylists;
    }

    get stepUpEarningsKey() {
        return STEP_UP_MILESTONE_KEY.stepUpEarnings;
    }

    get earnings() {
        const qualifiedEnrolleeCount = this
            .resolveCurrent(this.stepUpMilestone, this.achievedCountKey);

        const multiplier = Math.floor(qualifiedEnrolleeCount / 2);

        return multiplier * 100;
    }
}
