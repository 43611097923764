
import { Vue, Component, Prop } from 'vue-property-decorator';
import RankRequirementsItem from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/item.vue';
import MilestoneManager from '@/utils/milestoneManager';
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { MILESTONE_STATE_METRIC } from '@/modules/Dashboard/constants';

@Component({
    components: {
        RankRequirementsItem,
    },
})
export default class NewStartersContentStepsToSuccess extends Vue {
    @Prop() private steps!: MilestoneType[];

    @Prop() private isMobile!: boolean;

    resolveRequired(key: string): number {
        return MilestoneManager.resolveMilestoneRequired(key);
    }

    resolveCurrent(milestone: MilestoneType): number {
        return MilestoneManager.resolveMilestoneValue(milestone, MILESTONE_STATE_METRIC.total);
    }

    resolveLabel(key: string): string {
        return MilestoneManager.resolveMilestoneLabel(key);
    }
}
