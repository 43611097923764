
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import powerSellerClub from '@/modules/PowerSellerClub';
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import milestoneManager from '@/utils/milestoneManager';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import { defaultMilestone } from '@/modules/Dashboard/defaults';
import { MilestoneManagerPowerSellerRequirementsLevelType } from '@/utils/milestoneManager/types';
import labelResolver from '@/utils/label-resolver';
import { PowerSellerClubDisplayItemType } from './types';
import { defaultPowerSellerDisplayItem } from './defaults';
import TableRow from './TableRow/index.vue';
import Skeleton from './Skeleton/index.vue';

@Component({
    components: {
        Skeleton,
        TableRow,
    },
})
export default class PowerSellerClub extends Vue {
    @Get(powerSellerClub, 'data.powerSellerAnnual') private powerSellerAnnual!: MilestoneType[];

    @Get(powerSellerClub, 'data.powerSellerQuarterly') private powerSellerQuarterly!: MilestoneType[];

    @Get(powerSellerClub, 'data.powerSellerMonthly') private powerSellerMonthly!: MilestoneType[];

    @Get(powerSellerClub) private loading!: boolean;

    @Get(powerSellerClub) private loaded!: boolean;

    get isMocked() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }

    get currentDate(): string {
        return this.isMocked
            ? mockedDateManager.getCurrentDate(dateManager.getDayTimeFormat())
            : dateManager.getCurrentDateTime(dateManager.getDayTimeFormat());
    }

    get currentQuarter(): { quarter: number; milestone: MilestoneType } {
        let quarter = -1;

        const currentQuarter = this.powerSellerQuarterly.find((item, key) => {
            if (dateManager.isAfter(this.currentDate, item.startDate)
             && dateManager.isBefore(this.currentDate, item.endDate)) {
                quarter = key;
                return true;
            }

            return false;
        })!;

        if (quarter === -1) {
            return {
                quarter: -1,
                milestone: { ...defaultMilestone() },
            };
        }

        return {
            quarter,
            milestone: currentQuarter,
        };
    }

    get monthlyMilestone(): MilestoneType {
        const res = this.powerSellerMonthly[0];

        return res || defaultMilestone();
    }

    get monthlyVal(): string {
        const val = this.monthlyMilestone.state.find((item) => item.metric === 'total');

        return val ? val.value : '0';
    }

    get annualMilestone(): MilestoneType {
        const res = this.powerSellerAnnual[0];

        return res || defaultMilestone();
    }

    get annualVal(): string {
        const val = this.annualMilestone.state.find((item) => item.metric === 'total');

        return val ? val.value : '0';
    }

    get quarterMilestonesRenderList(): PowerSellerClubDisplayItemType[] {
        const { currentQuarter } = this;
        const res: PowerSellerClubDisplayItemType[] = [];
        const { monthlyMilestone } = this;

        const val = currentQuarter.milestone.state.find((item) => item.metric === 'total');

        const monthlyProgress = milestoneManager
            .resolvePowerSellerRequirements(monthlyMilestone.type, this.monthlyVal);

        const monthLevels = this.generateMilestoneLevels(monthlyProgress, 'Month', monthlyMilestone.endDate, this.monthlyVal);

        if (currentQuarter.quarter !== -1 && !!val) {
            const quarterProgress = milestoneManager
                .resolvePowerSellerRequirements(currentQuarter.milestone.type, val.value);

            this.generateMilestoneLevels(quarterProgress, 'Quarter', currentQuarter.milestone.endDate, val.value)
                .forEach((item, index) => {
                    res.push(monthLevels[index]);
                    res.push(item);
                });
        } else {
            monthLevels.forEach((item) => res.push(item));
        }

        return res;
    }

    get previousQuartersMilestonesRenderList(): PowerSellerClubDisplayItemType[] {
        const { currentQuarter } = this;
        const res: PowerSellerClubDisplayItemType[] = [];

        const powerSellerQuarterly = this.powerSellerQuarterly
            .filter((item, index) => index < currentQuarter.quarter);

        powerSellerQuarterly.forEach((current) => {
            let val = current.state.find((item) => item.metric === 'total');

            if (!val) {
                val = { metric: 'total', value: '0' };
            }

            const quarterProgress = milestoneManager
                .resolvePowerSellerRequirements(current.type, val.value);

            // eslint-disable-next-line max-len
            let achievedLevel: PowerSellerClubDisplayItemType = defaultPowerSellerDisplayItem();
            this.generateMilestoneLevels(quarterProgress, 'Quarter', current.endDate, val.value)
                .forEach((item, index) => {
                    if (index === 0 || (item.progress > 0 && item.progress < 100)) {
                        achievedLevel = item;
                    }
                });
            res.push(achievedLevel);
        });

        return res;
    }

    get annualMilestonesRenderList(): PowerSellerClubDisplayItemType[] {
        const { annualMilestone } = this;
        const res: PowerSellerClubDisplayItemType[] = [];

        const annualProgress = milestoneManager
            .resolvePowerSellerRequirements(annualMilestone.type, this.annualVal);

        this.generateMilestoneLevels(annualProgress, 'Annual', annualMilestone.endDate, this.annualVal)
            .forEach((item) => res.push(item));

        return res;
    }

    mounted() {
        powerSellerClub.getData();
    }

    generateMilestoneLevels(
        progress: MilestoneManagerPowerSellerRequirementsLevelType,
        periodLabel: string,
        dateTo: string,
        value: string,
    ): PowerSellerClubDisplayItemType[] {
        const resVal = (Number(value) / 100).toFixed(2);
        return [
            {
                color: progress.bronze.label,
                label: `${periodLabel} FLV ${progress.bronze.required}`,
                progress: progress.bronze.current,
                dateTo: `FLV to date ${dateManager.getDateTime(dateTo, dateManager.getDateFormatUK())}`,
                value: resVal,
            },
            {
                color: progress.silver.label,
                label: `${periodLabel} FLV ${progress.silver.required}`,
                progress: progress.silver.current,
                dateTo: `FLV to date ${dateManager.getDateTime(dateTo, dateManager.getDateFormatUK())}`,
                value: resVal,
            },
            {
                color: progress.gold.label,
                label: `${periodLabel} FLV ${progress.gold.required}`,
                progress: progress.gold.current,
                dateTo: `FLV to date ${dateManager.getDateTime(dateTo, dateManager.getDateFormatUK())}`,
                value: resVal,
            },
        ];
    }

    resolveQuarterLabel(index) {
        return `${labelResolver.whichResolver(index + 1)} Quarter`;
    }

    resolveCurrentFlvText(flv) {
        return `${flv} FLV`;
    }
}
