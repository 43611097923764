import { MILESTONE_TYPE } from '@/modules/Dashboard/constants';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { MilestoneType, RankRequirementsType, RankType } from './types';

export default class GetDashboardStatsQuery extends
    AbstractQueryResource<GetDashboardStatsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($runId: String!) {
            profile {
                stats(runId: $runId) {
                    metric,
                    value
                },
                rank(runId: $runId) {
                    id,
                },
                payRank(runId: $runId) {
                    id,
                }
                uniqueOrders: milestones(filter: { runId: $runId, type: "${MILESTONE_TYPE.uniqueOrderBonus}" }) {
                    type,
                    category,
                    key,
                    achieved,
                    achievedAt,
                    unachievable,
                    startDate,
                    endDate,
                    state {
                        metric,
                        value,
                    },
                },
            },
            ranks(runId: $runId) {
                id,
                label,
                power,
                requirements {
                    metric,
                    value
                },
                titleRequirements {
                    metric,
                    value
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getDashboardStats';
    }
}

export type GetDashboardStatsResultType = {
    profile: {
        stats: RankRequirementsType[];
        rank: {
            id:string
        }
        payRank: {
            id:string
        }
        uniqueOrders: MilestoneType[];
    };
    ranks: RankType[];
}

export type GetDashboardStatsParamsType = {
    runId: string;
}
