import mockedDateManager from '@/utils/mocked-date-manager';
import { ResolverCustomerOrderType } from '../types';
import { saleProduct } from './saleProduct';
import { product } from './products';
import { enrollee } from './enrollee';
import { salePayment } from './salePayment';

const deliveryCharge: string = '3.99';

const orders: ResolverCustomerOrderType[] = [
    {
        id: '1',
        customer: '1',
        event: '0',
        products: [
            saleProduct('1', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: null,
    }, {
        id: '2',
        customer: '2',
        event: '0',
        products: [
            saleProduct('2', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: true,
        comment: 'Generated',
        type: 'party',
    }, {
        id: '3',
        customer: '3',
        event: '1',
        products: [
            saleProduct('3', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '4',
        customer: '4',
        event: '1',
        products: [
            saleProduct('1', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '5',
        customer: '5',
        event: '2',
        products: [
            saleProduct('4', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: true,
        comment: 'Auto-generated',
        type: 'party',
    }, {
        id: '6',
        customer: '6',
        event: '5',
        products: [
            saleProduct('4', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '7',
        customer: '5',
        event: '5',
        products: [
            saleProduct('4', 1),
            saleProduct('5', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getLastDayOfThisMonth(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    },
    {
        id: '8',
        customer: '3',
        event: '17',
        products: [
            saleProduct('7', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '9',
        customer: '8',
        event: '17',
        products: [
            saleProduct('11', 1),
            saleProduct('4', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '10',
        customer: '4',
        event: '17',
        products: [
            saleProduct('9', 1),
            saleProduct('4', 1),
            saleProduct('7', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: true,
        comment: 'Auto-generated',
        type: null,
    }, {
        id: '11',
        customer: '9',
        event: '38',
        products: [
            saleProduct('8', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '12',
        customer: '6',
        event: '19',
        products: [
            saleProduct('5', 1),
            saleProduct('7', 1),
            saleProduct('10', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '13',
        customer: '2',
        event: '38',
        products: [
            saleProduct('3', 1),
            saleProduct('9', 2),
            saleProduct('6', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '14',
        customer: '13',
        event: '40',
        products: [
            saleProduct('7', 5),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '15',
        customer: '9',
        event: '39',
        products: [
            saleProduct('1', 2),
            saleProduct('6', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '16',
        customer: '2',
        event: '40',
        products: [
            saleProduct('2', 1),
            saleProduct('5', 1),
            saleProduct('4', 2),
            saleProduct('3', 1),
            saleProduct('11', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '17',
        customer: '8',
        event: '40',
        products: [
            saleProduct('2', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '18',
        customer: '12',
        event: '40',
        products: [
            saleProduct('11', 1),
            saleProduct('3', 3),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '19',
        customer: '5',
        event: '41',
        products: [
            saleProduct('5', 4),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: true,
        comment: 'Auto-generated',
        type: 'party',
    }, {
        id: '20',
        customer: '7',
        event: '42',
        products: [
            saleProduct('7', 3),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    },
    {
        id: '21',
        customer: '4',
        event: '43',
        products: [
            saleProduct('4', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '22',
        customer: '13',
        event: '42',
        products: [
            saleProduct('8', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '23',
        customer: '12',
        event: '44',
        products: [
            saleProduct('5', 1),
            saleProduct('2', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '24',
        customer: '10',
        event: '44',
        products: [
            saleProduct('5', 1),
            saleProduct('1', 2),
            saleProduct('4', 4),
            saleProduct('2', 1),
            saleProduct('10', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '25',
        customer: '7',
        event: '45',
        products: [
            saleProduct('11', 4),
            saleProduct('9', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '26',
        customer: '3',
        event: '45',
        products: [
            saleProduct('7', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '27',
        customer: '11',
        event: '45',
        products: [
            saleProduct('4', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '28',
        customer: '9',
        event: '45',
        products: [
            saleProduct('8', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '29',
        customer: '8',
        event: '46',
        products: [
            saleProduct('4', 12),
            saleProduct('9', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '30',
        customer: '7',
        event: '47',
        products: [
            saleProduct('1', 3),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '31',
        customer: '4',
        event: '47',
        products: [
            saleProduct('8', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    },
    {
        id: '32',
        customer: '5',
        event: '48',
        products: [
            saleProduct('11', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: true,
        comment: 'Auto-generated',
        type: 'party',
    }, {
        id: '33',
        customer: '10',
        event: '48',
        products: [
            saleProduct('4', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '34',
        customer: '9',
        event: '48',
        products: [
            saleProduct('5', 1),
            saleProduct('1', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '35',
        customer: '8',
        event: '48',
        products: [
            saleProduct('6', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '36',
        customer: '9',
        event: '49',
        products: [
            saleProduct('4', 1),
            saleProduct('5', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '37',
        customer: '5',
        event: '50',
        products: [
            saleProduct('9', 3),
            saleProduct('4', 1),
            saleProduct('5', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '38',
        customer: '1',
        event: '51',
        products: [
            saleProduct('7', 3),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '39',
        customer: '7',
        event: '51',
        products: [
            saleProduct('1', 4),
            saleProduct('3', 2),
            saleProduct('4', 3),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '40',
        customer: '9',
        event: '52',
        products: [
            saleProduct('9', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    },
    {
        id: '41',
        customer: '1',
        event: '52',
        products: [
            saleProduct('6', 1),
            saleProduct('7', 3),
            saleProduct('2', 2),
            saleProduct('5', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '42',
        customer: '5',
        event: '53',
        products: [
            saleProduct('6', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '43',
        customer: '2',
        event: '53',
        products: [
            saleProduct('3', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '44',
        customer: '9',
        event: '54',
        products: [
            saleProduct('1', 2),
            saleProduct('6', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '45',
        customer: '9',
        event: '54',
        products: [
            saleProduct('7', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '46',
        customer: '8',
        event: '55',
        products: [
            saleProduct('11', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '47',
        customer: '5',
        event: '55',
        products: [
            saleProduct('3', 1),
            saleProduct('10', 1),
            saleProduct('4', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '48',
        customer: '4',
        event: '56',
        products: [
            saleProduct('8', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '49',
        customer: '13',
        event: '57',
        products: [
            saleProduct('6', 2),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    }, {
        id: '50',
        customer: '2',
        event: '57',
        products: [
            saleProduct('8', 2),
            saleProduct('1', 1),
        ],
        value: 0.00,
        commisionableValue: 0.00,
        psc: 2,
        date: mockedDateManager.getCurrentDate(),
        shippingCost: deliveryCharge,
        paymentInfo: [salePayment()],
        manual: false,
        comment: null,
        type: 'party',
    },
];

const customersOrders: (
    enrolleeId: string
) => ResolverCustomerOrderType[] = (enrolleeId) => orders
    .filter((order: ResolverCustomerOrderType) => order.customer === enrolleeId);

const customersSales: (
    enrolleeId: string
) => ResolverCustomerOrderType[] = (enrolleeId) => {
    const { customers } = enrollee(enrolleeId);
    return orders
        .filter((order: ResolverCustomerOrderType) => customers.indexOf(order.customer) > -1);
};

const eventOrders: (eventId: string) => ResolverCustomerOrderType[] = (eventId) => orders
    .filter((sale) => sale.event === eventId);

const order: (id: string) => ResolverCustomerOrderType = (id) => orders
    .find((item) => item.id === id)!;

const orderTotal: (id: string) => number = (id) => {
    const thisOrder: ResolverCustomerOrderType = order(id);
    let total = 0;

    thisOrder.products.forEach((item) => {
        const productElem = product(item.id);
        const price = Number(productElem.price) * 100;
        const productTotal = price * Number(item.quantity);
        total += productTotal;
    });

    return total;
};

export {
    customersOrders, customersSales, order, orderTotal,
    eventOrders,
};
