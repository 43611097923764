
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import rank from '@/modules/Rank';
import auth from '@/modules/Auth';
import env from '@/environment';
import dashboard from '@/modules/Dashboard';
import RankRequirementManager from '@/utils/rankRequirementManager';
import dashboardStats from '@/modules/Dashboard/stats';
import { RunsType, StatsType } from '@/api/graphQL/graphNodes/types';
import Skeleton from './DashboardSkeleton/index.vue';
import RankContainer from './RankContainer/index.vue';
import MonthPerformance from './MonthPerformance/index.vue';
import RecruitmentPerformance from './RecruitmentPerformance/index.vue';
import UniqueOrders from './UniqueOrders/index.vue';
import StepsToSuccess from './StepsToSuccess/index.vue';
// import Pathway from './Pathway/index.vue';
// import AwardContainer from './Rankings/AchievementAwards/index.vue';

@Component({
    components: {
        // AwardContainer,
        RankContainer,
        MonthPerformance,
        RecruitmentPerformance,
        UniqueOrders,
        Skeleton,
        // Pathway,
        StepsToSuccess,
    },
})
export default class Dashboard extends Vue {
    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(dashboard, 'loading') private dashboardLoading!: boolean;

    @Get(dashboard) private loadingTotals!: boolean;

    @Get(dashboard, 'data.participatedRuns') private participatedRuns!: RunsType[];

    @Get(dashboardStats) private selectedRun!: string;

    @Get(dashboardStats) private stats!: StatsType[];

    get displayUniqueOrdersElement(): boolean {
        const fullMonthsStat = this.stats.find((stat) => stat.metric === RankRequirementManager
            .rankRequirementMetricConstants.fullMonthsSinceJoin);

        return !!fullMonthsStat && Number(fullMonthsStat.value) >= 3;
    }

    get viewLoaded(): boolean {
        return !this.ranksLoading && !this.dashboardLoading && !this.loadingTotals;
    }

    get unlockAfterPhase(): boolean {
        return Number(env.VUE_APP_STAGE) > 1;
    }

    get isAmbassador() {
        return auth.isAmbassador;
    }

    get currentRun(): boolean {
        const current = this.participatedRuns[0];

        return current ? current.id === this.selectedRun : false;
    }

    async beforeMount() {
        rank.getRanks();
        dashboard.getDashboardData();
    }
}
