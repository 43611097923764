import { MilestoneManagerPowerSellerRequirementsLevelType, MilestoneManagerPowerSellerRequirementType } from './types';

export const defaultPowerSellerRequirement:
 () => MilestoneManagerPowerSellerRequirementType = () => ({
     label: '',
     required: -1,
     current: 0,
 });

export const defaultPowerSellerRequirements:
 () => MilestoneManagerPowerSellerRequirementsLevelType = () => ({
     bronze: defaultPowerSellerRequirement(),
     silver: defaultPowerSellerRequirement(),
     gold: defaultPowerSellerRequirement(),
 });
