
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PowerSellerClubRowProgress extends Vue {
    @Prop({ required: true }) private progress!: number;

    @Prop({ required: true }) private label!: string;

    get progressDoneClasses() {
        return 'PowerSellerClub__ProgressCompleteIconDone';
    }
}
