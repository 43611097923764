import { FlagFullType } from '@/api/graphQL/graphNodes/types';
import { JOAH_FLAG_KEY } from '@/modules/constants';

const flagsList: FlagFullType[] = [
    {
        key: JOAH_FLAG_KEY,
        value: false,
        type: 'bool',
        label: 'Hide Joah Products',
        description: 'Does the stylist agree to sell Joah products',
    },
];

const flags:() => FlagFullType[] = () => flagsList;
export { flags };
