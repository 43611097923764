// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLScalarType } from 'graphql';
import {
    RankType, MeType, EnrolleeType, AssetCategoryType,
    StatsType, HostessRewardsType, RunsType, EventShippingAddressType, BusinessDetailsType,
    MilestoneType,
    RunTotalType,
} from '@/api/graphQL/graphNodes/types';
import { defaultAsset } from '@/modules/defaults';
import { LEXIS_NEXIS_STATUS } from '@/modules/Register/constants';
import dateManager from '@/utils/time';
import { CountriesType } from '@/modules/Event/types';
import { CreateEventResultType } from '@/api/graphQL/graphNodes/typesResult';
import typeDefs from './typeDefs';
import data from './data';
import {
    ExploreEnrolleeType, RankRequirementType, ResolverEventType,
    ResolverCustomerOrderType, ResolverEnrolleeType,
    ResolverEventGuestType, ResolverInvitationType,
    ResolverSearchAssetsCountParamsType, ResolverSearchAssetsParamsType, ResolverAssetType,
    ResolverIncomeSummaryType,
    ResolverTransactionType,
    ResolverReportType,
    ResolverTermsAndConditionsType,
    ResolverAchievementAwardType,
} from './types';
import { events, getPartiesTotalSales } from './data/events';
import { enrollees } from './data/enrollee';
import { enrolleeCustomers } from './data/customers';
import getToken from './services/tokenResolver';
import { defaultResolverEnrollee, defaultResolverEvent } from './defaults';
import { customersSales, eventOrders } from './data/sales';
import { eventShippingAddress } from './data/eventShippingAddress';

const resolvers: any = {
    Query: {
        profile: (): ResolverEnrolleeType => data.enrollee(getToken()),
        ranks: (): RankType[] => data.ranks,
        enrollee: (_, { id = getToken() }): ResolverEnrolleeType => data.enrollee(id),
        exploreEnrollee: (_, { id = getToken() }): ExploreEnrolleeType => {
            const item = data.enrollee(id);

            return {
                enrollee: item,
                breadcrumbs: data.enrolleeBreadcrumbs(<string>item!.id),
            };
        },
        me: (): MeType => data.me,
        customerOrders: (_, { customerId = getToken() }): ResolverCustomerOrderType[] => data
            .sales(customerId),
        events: (_, {
            date, offset, limit, type,
        }): ResolverEventType[] => (data.findEvents(date, offset, limit, type)),
        event: (_, { id }): ResolverEventType|undefined => data.event(id),
        eventsTotals: (_, params, __, info) => {
            if (info.operation.name.value === 'getDashboard') {
                return data.eventsStatistics(params, { month: info.path.key });
            }
            return data.eventsStatistics(params);
        },
        shareEvent: (_, { id }): string => `/send-event-invite?event=${id}`,
        impersonateEnrollee: (_, { enrolleeId }): string => String(enrolleeId),
        assets: (_, { category = '' }): ResolverAssetType[] => data.assets(category),
        searchAssets: (_, params: ResolverSearchAssetsParamsType): ResolverAssetType[] => data
            .searchAssets(params),
        searchAssetsCount: (_, params: ResolverSearchAssetsCountParamsType): number => data
            .searchAssetsCount(params),
        calendar: (_, {
            dateFrom, dateTo, offset, limit, type,
        }) => (data.calendar(dateFrom, dateTo, offset, limit, type)),
        calendarDaily: (_, {
            dateFrom, dateTo, offset, limit, type,
        }) => (data.calendar(dateFrom, dateTo, offset, limit, type)),
        assetCategories: (_, params): AssetCategoryType[] => data.assetCategories(params.type),
        termsAndConditions: (): ResolverTermsAndConditionsType => data.termsAndConditions(),
        invitationForEvent: (_, { eventId }): ResolverInvitationType => data
            .eventInvitation(eventId),
        hostessRewards: ():HostessRewardsType[] => data.hostessRewards(),
        countries: (): CountriesType[] => data.countries(),
        regions: (): CountriesType[] => data.regions(),
        achievementAwards: (_, {
            type, limit, skip, query,
        }): ResolverAchievementAwardType => data
            .achievementAwardByType(getToken(), limit, skip, type, query),
        achievementAwardsCount: (_, { query, type }): Number => data
            .achievementAwardByType(getToken(), 10, 0, type, query).leaderboardLength,
        dashboardAchievementAwards: (): ResolverAchievementAwardType[] => data
            .achievementAward(getToken()),
        eventGuestsInviteOptionEnabled: () => false,
    },
    Mutation: {
        updateProfile: (_, { profile }) => {
            data.profile = { ...data.profile, ...profile };
            return data.profile;
        },
        updateDownlineProfile: (_, { enrolleeId }) => data.enrollee(enrolleeId),
        createEvent: (_, params): CreateEventResultType => {
            const { event } = params;

            const createdEvent = {
                ...event,
                id: String(events.length),
                confirmed: false,
                invitations: [],
                orders: [],
            };

            if (event.hostessInvitation && enrollees
                .find((item: ResolverEnrolleeType) => (`${item.firstName} ${item.lastName}`)
                    === event.hostessInvitation.name)
            ) {
                const host = {
                    id: String(enrollees.length - 1),
                    ...event.hostessInvitation,
                    parentId: getToken(),
                };
                enrollees.push(host);
                const parentEnrollee = data.enrollee(getToken());
                parentEnrollee.customers.push(host.id);
                parentEnrollee.risingStars.push(host.id);
                parentEnrollee.directDownline.push(host.id);

                return host;
            }

            events.push(createdEvent);

            return createdEvent;
        },
        editEvent: (_, params) => {
            const { id } = params;
            const event = data.event(id);

            return event;
        },
        cancelEvent: (_, { id }) => {
            const event = data.event(id);
            return { ...event, canceled: true };
        },
        inviteGuest: (_, { eventId, invitation }): ResolverInvitationType => {
            const event = data.event(eventId);
            event!.invitations.push(invitation);

            return {
                ...data.ambassadorInvitation(),
            };
        },
        inviteAmbassador: (_, { email, firstName, lastName }): ResolverInvitationType => ({
            ...data.ambassadorInvitation(),
            email,
            name: `${firstName} ${lastName}`,
        }),
        registerAmbassador: (_, props): ResolverEnrolleeType => {
            const {
                email, firstName, lastName, address, phone,
            } = props.register;
            return ({
                ...defaultResolverEnrollee(),
                email,
                address,
                firstName,
                lastName,
                phoneNumber: phone,
                lexisNexisStatus: (firstName === 'flagged') ? LEXIS_NEXIS_STATUS.refer : LEXIS_NEXIS_STATUS.pass,
            });
        },
        uploadProfilePicture: (): ResolverAssetType => ({
            ...defaultAsset(),
            subCategory: '',
        }),
        respondToInvitation: (_, { eventId, attending }) => {
            const invitation = data.eventInvitation(eventId);
            return {
                ...invitation,
                attending,
            };
        },
        registerDriverLicense: (): ResolverEnrolleeType => data.enrollee(getToken()),
        registerPassport: (): ResolverEnrolleeType => data.enrollee(getToken()),
    },
    Enrollee: {
        rank: (enrollee): RankType|undefined => data.ranks
            .find((item) => item.id === enrollee.rankId),
        payRank: (enrollee): RankType|undefined => data.ranks
            .find((item) => item.id === enrollee.payRankId),
        highestAchievedRank: (enrollee): RankType|undefined => data.ranks
            .find((item) => item.id === enrollee.highestAchievedRankId),
        directDownline: (enrollee, { limit, offset, aofFilter = null }):
         ResolverEnrolleeType[] => data
            .directDownline(
                enrollee.directDownline, limit, offset, aofFilter,
            ),
        searchDirectDownline: (enrollee, { query, aofFilter = null }): EnrolleeType[] => {
            const res = enrollee
                .directDownline.map((leg) => data.enrollee(leg))
                .filter((item: EnrolleeType) => {
                    if (query) {
                        return (`${item.firstName} ${item.lastName}`).toLowerCase().includes(query.toLowerCase());
                    }
                    return true;
                })
                .filter((item) => (!aofFilter || item.aOFStatus === aofFilter));

            return res;
        },
        directDownlineCount: (enrollee, { aofFilter }): number => enrollee.directDownline
            .map((leg) => data.enrollee(leg))
            .filter((item) => (!aofFilter || item.aOFStatus === aofFilter))
            .length,
        personalRecruitsCount: (enrollee, { from, to }): number => data
            .personalRecruits(enrollee.directDownline, from, to).length,
        leaderLegs: (enrollee, { limit, offset }): EnrolleeType[] => enrollee
            .leaderLegs.map((leg) => data.enrollee(leg)).splice(offset, limit),
        searchLeaderLegs: (enrollee, { query }): EnrolleeType[] => enrollee
            .leaderLegs.map((leg) => data.enrollee(leg))
            .filter((item: EnrolleeType) => (`${item.firstName} ${item.lastName}`)
                .toLowerCase().includes(query.toLowerCase())),
        leaderLegsCount: (enrollee): number[] => enrollee.leaderLegs.length,
        qualifiedLeaderLegsCount: (enrollee): number => (enrollee.id === '0'
            ? 2 : enrollee.leaderLegs.length),
        qualifiedBranchesCount: (enrollee): number => (enrollee.id === '0'
            ? 3 : enrollee.leaderLegs.length),
        risingStars: (enrollee, props): ResolverEnrolleeType[] => data
            .risingStars(enrollee.id, props),
        searchRisingStars: (enrollee, { query }): EnrolleeType[] => enrollee
            .risingStars.map((leg) => data.enrollee(leg))
            .filter((item: EnrolleeType) => (`${item.firstName} ${item.lastName}`)
                .toLowerCase().includes(query.toLowerCase())),
        risingStarsCount: (enrollee): EnrolleeType[] => enrollee
            .risingStars.length,
        stats: (enrollee): StatsType[] => data.stats(enrollee.id),
        parent: (enrollee): ResolverEnrolleeType => data.enrollee(enrollee.parentId),
        searchCustomers: (enrollee, {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            offset, limit, query, direct,
        }): ResolverEnrolleeType[] => {
            if (query === '') {
                return enrolleeCustomers(enrollee.id).splice(offset, limit);
            }
            return enrolleeCustomers(enrollee.id)
                .filter((item) => (`${item.firstName} ${item.lastName} ${item.email}`)
                    .toLowerCase().includes(query.toLowerCase()))
                .splice(offset, limit);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        searchCustomersCount: (enrollee, { query, direct }): number => {
            if (query === '') {
                return enrolleeCustomers(enrollee.id).length;
            }
            return enrolleeCustomers(enrollee.id)
                .filter((item) => (`${item.firstName} ${item.lastName} ${item.email}`)
                    .toLowerCase().includes(query.toLowerCase())).length;
        },
        totalSales: (enrollee): string => data.totalCustomerSales(enrollee.id),
        searchHosts: (_, { offset, limit, query }): ResolverEnrolleeType[] => {
            if (query === '') {
                if (limit <= 0) {
                    return data.hostesses();
                }
                return data.hostesses().splice(offset, limit);
            }
            const filteredHost = data.hostesses()
                .filter((item) => (`${item.firstName} ${item.lastName}`)
                    .toLowerCase().includes(query.toLowerCase()));
            if (limit <= 0) {
                return filteredHost;
            }
            return filteredHost.splice(offset, limit);
        },
        searchHostsCount: (_, { query }): number => {
            if (query === '') {
                return data.hostesses().length;
            }
            return data.hostesses().filter((item) => (`${item.firstName} ${item.lastName}`)
                .toLowerCase().includes(query.toLowerCase())).length;
        },
        ambassadorsCountsByActivityLevel: (enrollee, params): RankRequirementType[] => data
            .ambassadorCountStat(enrollee.id, params),
        newStarters: (enrollee, params): ResolverEnrolleeType[] => data
            .newStarters(enrollee.id, params),
        searchNewStarters: (enrollee, { date, type, query }): ResolverEnrolleeType[] => data
            .newStarters(enrollee.id, { date, type })
            .filter((item: ResolverEnrolleeType) => (`${item.firstName} ${item.lastName}`)
                .toLowerCase().includes(query.toLowerCase())),
        uplineLeader: (enrollee): ResolverEnrolleeType => (enrollee.uplineLeaderId === null
            ? enrollee.uplineLeaderId : data.enrollee(enrollee.uplineLeaderId)),
        centralTeamCount: (enrollee): number => (enrollee.id === '0'
            ? 57 : enrollee.centralTeam.length),
        organizationCount: (enrollee): number => (enrollee.id === '0'
            ? 234 : enrollee.organisational.length),
        newStartersCount: (enrollee, params): number => {
            if (enrollee.id === '0') {
                if (params.type === 'personal') {
                    return 6;
                } if (params.type === 'central_team') {
                    return 16;
                }
                return 32;
            }

            return 0;
        },
        newStartersCommissionEngine: (enrollee, params): ResolverEnrolleeType[] => data
            .newStarters(enrollee.id, params),
        newStartersCountCommissionEngine: (enrollee, params): number => data
            .newStarters(enrollee.id, params).length,
        profilePicture: (): ResolverAssetType => ({ ...defaultAsset(), subCategory: '' }),
        searchOrders: (enrollee, { offset = 0, limit = 10, query }) => {
            if (query === '') {
                return customersSales(enrollee.id).splice(offset, limit);
            }
            return customersSales(enrollee.id).filter((item) => item.id.includes(query))
                .splice(offset, limit);
        },
        searchOrdersCount: (enrollee, { query }): number => {
            if (query === '') {
                return customersSales(enrollee.id).length;
            }
            return customersSales(enrollee.id).filter((item) => item.id.includes(query))
                .length;
        },
        incomeSummary: (enrollee, params): ResolverIncomeSummaryType => data
            .incomeSummary(params.year, enrollee.id),
        commissionStatements: () => [],
        personalStatements: () => [],
        personalStatementsCount: () => 0,
        transactions: (enrollee, { offset = 0, limit = 10 }): ResolverTransactionType[] => data
            .transactions(enrollee.id)
            .splice(offset, limit),
        transactionsCount: (enrollee): number => data
            .transactions(enrollee.id)
            .length,
        balance: (enrollee): StatsType => data.balance(enrollee.id),
        shopLoginUrl: (enrollee): string => enrollee.shopUrl,
        participatedRuns: (): RunsType[] => data.runs(),
        reports: (enrollee, params): ResolverReportType[] => data.reports(params.runId),
        editable: () => true,
        businessDetails: (enrollee): null|BusinessDetailsType => data.businessDetails(enrollee.id),
        suspension: () => false,
        milestones: (enrollee, props): MilestoneType[] => data.milestone(enrollee.id, props.filter),
        agreements: () => [],
        runTotalsForPeriod: (enrollee): RunTotalType[] => data.getRunTotals(enrollee.id),
        activeGroupEnrolleesCount: (enrollee): number => enrollee.qualifiedFrontlineLeadersCount,
        userId: () => null,
        shopPreAuthUrl: (enrollee) => enrollee.shopUrl,
        flags: () => data.flags(),
    },
    Rank: {
        requirements: (rank): RankRequirementType[] => data.rankRequirement(rank.id),
        titleRequirements: (rank): RankRequirementType[] => data.rankRequirement(rank.id),
    },
    Event: {
        hostess: (event: ResolverEventType): ResolverEnrolleeType|null => (event.hostess
            ? data.enrollee(event.hostess) : null),
        ambassador: (event: ResolverEventType): ResolverEnrolleeType => data
            .enrollee(event.ambassador),
        invitations: (event: ResolverEventType): ResolverEventGuestType[] => event.invitations
            .map((invitation) => {
                const enrollee = data.enrollee(invitation.enrollee! as string);
                return {
                    ...invitation,
                    enrollee,
                    name: `${enrollee.firstName} ${enrollee.lastName}`,
                    email: enrollee.email,
                };
            }),
        eventOrders: (event: ResolverEventType, {
            offset = 0,
            limit = 0,
        }): ResolverCustomerOrderType[] => {
            const eventOrdersList = eventOrders(event.id);

            if (limit > 0) {
                return eventOrdersList.splice(offset, limit);
            }

            return eventOrdersList;
        },
        eventOrdersCount: (event: ResolverEventType): number => eventOrders(event.id).length,
        totalSales: (event: ResolverEventType): number => getPartiesTotalSales([event]),
        shippingAddress: (event: ResolverEventType)
            : EventShippingAddressType => eventShippingAddress(event),
        eventShopUrl: (event: ResolverEventType): string => `https://meamora.co.uk/event?id=${event.eventCode}`,
    },
    Sale: {
        id: (customerOrderElem: ResolverCustomerOrderType): string => `#${customerOrderElem.id}`,
        enrollee: (customerOrderElem: ResolverCustomerOrderType):
         ResolverEnrolleeType => {
            const event: ResolverEventType = data
                .event(customerOrderElem.event) || defaultResolverEvent();

            return data.enrollee(event.ambassador);
        },
        customer: (customerOrderElem: ResolverCustomerOrderType):
         ResolverEnrolleeType => data.enrollee(customerOrderElem.customer),
        value: (customerOrderElem: ResolverCustomerOrderType):
         string => String(data.orderTotal(customerOrderElem.id) / 100),
        grossValue: (customerOrderElem: ResolverCustomerOrderType):
         string => String(data.orderTotal(customerOrderElem.id) / 100),
        commissionableValue: (customerOrderElem: ResolverCustomerOrderType): string => {
            const sum = Math.floor(data.orderTotal(customerOrderElem.id) * 0.15);

            return String(sum / 100);
        },
        discount: () => '5.50',
        products: (customerOrderElem: ResolverCustomerOrderType) => [...customerOrderElem.products],
        referenceId: (customerOrderElem: ResolverCustomerOrderType) => `#ref-${customerOrderElem.id}`,
        qualificationValue: (customerOrderElem: ResolverCustomerOrderType): string => {
            const totalOrder = data.orderTotal(customerOrderElem.id);
            return String((totalOrder > 300 ? (totalOrder - 300) : totalOrder) / 100);
        },
    },
    Asset: {
        subCategory: (asset): AssetCategoryType => data
            .assetCategories(asset.category)
            .find((category) => asset.subCategory === category)!,
    },
    Report: {
        fileName: (report: ResolverReportType): string => {
            const runs: RunsType[] = data.runs();
            const run: RunsType = runs.find((item) => item.id === report.runId)!;
            const generatedAt = dateManager.getDateTime(run.to, dateManager.getDateFormat());
            const enrollee = data.enrollee(getToken());
            const title = `${enrollee.firstName}_${enrollee.lastName}_${report.fileName}_${generatedAt}`.toLocaleUpperCase();
            return `${title}.csv`;
        },
    },
    DateTime: new GraphQLScalarType({
        name: 'DateTime',
        description: 'DateTime custom scalar type',
        serialize(value) {
            return value.getTime();
        },
    }),
};

export { resolvers, typeDefs };
