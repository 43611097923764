// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { RegisterAmbassadorFullType } from './types';

export default class RegisterAmbassadorQuery extends
    AbstractQueryResource<RegisterAmbassadorResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($register: ProfileRegisterInput) {
            registerAmbassador(register: $register) {
                lexisNexisStatus,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'registerAmbassador';
    }
}

export type RegisterAmbassadorParamsType = {
    register: RegisterAmbassadorFullType;
}

export type RegisterAmbassadorResultType = {
    registerAmbassador: {
        lexisNexisStatus: string|null;
    }
}
