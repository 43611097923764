
import { Get } from '@/utils/vuex-module-mutators';
import { Vue, Component, Prop } from 'vue-property-decorator';
import system from '@/modules/System';
import ProgressElement from './Progress/index.vue';

@Component({
    components: {
        ProgressElement,
    },
})
export default class PowerSellerClubTableRow extends Vue {
    @Get(system) private screenType!: string;

    @Prop({ required: true }) private color!: string;

    @Prop({ required: true }) private label!: string;

    @Prop({ required: true }) private secondColumnText!: string;

    @Prop({ required: true }) private currentFlvText!: string;

    @Prop({ required: true }) private progress!: number;

    get isMobile() {
        return this.screenType === 'mobile';
    }

    resolveColorClass(color) {
        return `PowerSellerClub__Milestone--${color}`;
    }
}
