import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import { COUNTRY_SELECT_OPTIONS, REGION_OPTIONS } from '@/modules/constants';
import { LEXIS_NEXIS_STATUS } from '@/modules/Register/constants';
import { RunTotalType } from '@/api/graphQL/graphNodes/types';
import { ResolverEnrolleeType } from '../types';

const enrollees: ResolverEnrolleeType[] = [
    {
        id: '-1',
        parentId: null,
        uplineLeaderId: null,
        totals: {
            gv: 3500,
            spa_gv: 3500,
            ov: 6750,
            pv: 1850,
        },
        // PS = 150+
        // Through all downline until TA.
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        // personalRecruitsCount all this month. PS = 150+
        rankId: '3',
        highestAchievedRankId: '3',
        payRankId: '3',
        directDownline: ['0'],
        leaderLegs: ['0'],
        qualifiedLeaderBranchIds: [],
        risingStars: ['3', '5'],
        phoneNumber: '078 5091 7478',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'emma.mckenzie@mailinator.com',
        firstName: 'Emma',
        lastName: 'McKenzie',
        address: "61 St Giles', Oxford OX1 3LZ, United Kingdom",
        postCode: 'OX1 3LZ',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1978-12-03T16:32:12+00:00',
        joinDate: `${dateManager.getDateWithOffset(-12, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'month')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-12, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'month')}`,
        paidForKit: true,
        customers: ['12'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '54.31',
        ctb: '118.00',
        leadershipBonus: '55.02',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'emmmck1',
        shopUrl: 'https://meamora.co.uk/search?q=-1',
        customerNumber: '1',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '0',
        parentId: '-1',
        uplineLeaderId: '-1',
        totals: {
            gv: 12395,
            spa_gv: 12395,
            ov: 34175,
            pv: 928,
        },
        ambassadorLegsCount: 17,
        qualifiedFrontlineLeadersCount: 17,
        activeOnFileDirectEnrollees: 17,
        rankId: '1',
        highestAchievedRankId: '1',
        payRankId: '1',
        directDownline: ['1', '2', '3', '4', '5', '12', '18', '25', '45', '48', '51', '54'],
        leaderLegs: ['18', '25', '45', '48', '51', '54'],
        qualifiedLeaderBranchIds: ['18', '25'],
        risingStars: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14',
            '15', '16', '17', '19', '20', '21', '22', '23', '24', '26', '27', '28', '29', '30', '32',
            '33', '34', '35', '36', '38', '39', '41', '42', '44', '46', '47', '49', '50', '52', '53',
            '55', '56'],
        phoneNumber: '+442018752148',
        secondPhoneNumber: null,
        secondAddress: '38 Coast Rd KIRKBY LA THORPE',
        postCode: 'NG34 9WX',
        email: 'Sarah.hill@mailinator.com',
        firstName: 'Sarah',
        lastName: 'Hill',
        address: "61 St Giles', Oxford OX1 3LZ, United Kingdom",
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1975-06-10T15:02+03:00',
        joinDate: `${dateManager.getDateWithOffset(-11, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'month')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-11, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'month')}`,
        paidForKit: true,
        customers: ['1', '4', '5', '2', '7', '3', '6', '0'],
        personal: ['1', '2', '3', '4', '5', '12', '18', '25'],
        centralTeam: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13',
            '14', '15', '16', '17'],
        organisational: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13',
            '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27',
            '28', '29', '30', '31', '32', '33', '34', '35', '36'],
        additionalCommission: '0.00',
        ctb: '1008.36',
        leadershipBonus: '407.75',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: '',
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 2,
        vanityUrl: 'sarhil1',
        shopUrl: 'https://meamora.co.uk/search?q=0',
        customerNumber: '0',
        canRegister: true,
        shadow: true,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '1',
        parentId: '0',
        uplineLeaderId: '-1',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 388,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+441895471284',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'olivia.doup@mailinator.com',
        firstName: 'Olivia',
        lastName: 'Doup',
        address: '11 Leonard St, Old Street, London, United Kingdom',
        postCode: 'EC2A 4AQ',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1993-04-07T12:16:55+00:00',
        joinDate: `${dateManager.getDateWithOffset(-97, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-97, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: ['8', '9'],
        // personal: ['12', '44'],
        // centralTeam: ['9', '12', '14', '30', '44'],
        // organisational: ['9', '12', '14', '30', '32', '44'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '77.50',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'olidou1',
        shopUrl: 'https://meamora.co.uk/search?q=1',
        customerNumber: '1',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: true,
        aOFStatus: 'Y',
    },
    {
        id: '2',
        parentId: '0',
        uplineLeaderId: null,
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 239,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+448143684285',
        secondPhoneNumber: '+44 77 0607 4226',
        secondAddress: null,
        email: 'ava.poaer@mailinator.com',
        firstName: 'Ava',
        lastName: 'Poaer',
        address: '30 Bloomsbury Square, Holborn, London, United Kingdom',
        postCode: 'WC1A 2RJ',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1985-01-13T17:20:13+00:00',
        joinDate: `${dateManager.getDateWithOffset(-27, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-28, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: ['10'],
        // personal: ['3', '13', '19', '25', '29', '42', '50', '56'],
        // centralTeam: ['3', '13', '19', '25', '29', '42', '50', '56'],
        // organisational: ['3', '13', '19', '25', '29', '42', '50', '56'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '75.50',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'avapoa1',
        shopUrl: 'https://meamora.co.uk/search?q=2',
        customerNumber: '2',
        canRegister: true,
        shadow: true,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '3',
        parentId: '0',
        uplineLeaderId: null,
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 323,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+447292185478',
        secondPhoneNumber: '+447848852934',
        secondAddress: '37 Princes Street ROLLESTONE SP3 8UU',
        postCode: 'SP3 8UU',
        email: 'isabella.thomas@mailinator.com',
        firstName: 'Isabella',
        lastName: 'Thomas',
        address: 'Trafalgar Square, Charing Cross, London WC2N 5DN, United Kingdom',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1993-12-01T10:13:17+00:00',
        joinDate: `${dateManager.getDateWithOffset(-31, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-31, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        // personal: ['0', '6', '15', '20', '22', '24', '28', '35', '43', '52'],
        // centralTeam: ['0', '6', '15', '20', '22', '24', '28', '35', '43', '52'],
        // organisational: ['0', '6', '15', '20', '22', '24', '28', '35', '43', '52'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '75.50',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'isatho1',
        shopUrl: 'https://meamora.co.uk/search?q=3',
        customerNumber: '3',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '4',
        parentId: '0',
        uplineLeaderId: '-1',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 244,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+441987514598',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'sophia.jaqueen@mailinator.com',
        firstName: 'Sophia',
        lastName: 'Jaqueen',
        address: 'S Parks Rd, Oxford OX1 3QR, United Kingdom',
        postCode: 'OX1 3QR',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1989-11-03T11:10:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-28, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-28, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        // personal: ['2', '5', '8', '11', '18', '21', '27', '41', '45', '46', '51', '57', '58'],
        // centralTeam: ['2', '5', '8', '11', '18', '21', '27', '41', '45', '46', '51', '57', '58'],
        // organisational: ['2', '5', '8', '11', '18', '21', '27', '41', '45', '46', '51', '57',
        //  '58'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '75.50',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'sopjaq1',
        shopUrl: 'https://meamora.co.uk/search?q=4',
        customerNumber: '4',
        canRegister: true,
        shadow: true,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '5',
        parentId: '0',
        uplineLeaderId: '0',
        totals: {
            gv: 3373,
            spa_gv: 3373,
            ov: 3373,
            pv: 432,
        },
        ambassadorLegsCount: 6,
        qualifiedFrontlineLeadersCount: 6,
        activeOnFileDirectEnrollees: 6,
        rankId: '0',
        highestAchievedRankId: '1',
        payRankId: '1',
        directDownline: ['6', '7', '8', '9', '10', '11'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['6', '7', '8', '9', '10', '11'],
        phoneNumber: '+441978547398',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'amelia.smith@mailinator.com',
        firstName: 'Amelia',
        lastName: 'Smith',
        address: '83a, 85 London Rd',
        postCode: 'OX3 9AJ',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: 'Headington',
        town: 'Oxford',
        birthDate: '1990-03-20T13:44:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-92, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-92, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: false,
        customers: [],
        personal: ['6', '7', '8', '9', '10', '11'],
        centralTeam: ['6', '7', '8', '9', '10', '11'],
        organisational: ['6', '7', '8', '9', '10', '11'],
        additionalCommission: '0.00',
        ctb: '398.88',
        leadershipBonus: '41.62',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'amesmi1',
        shopUrl: 'https://meamora.co.uk/search?q=5',
        customerNumber: '5',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '6',
        parentId: '5',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 353,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'emily.jones@mailinator.com',
        firstName: 'Emily',
        lastName: 'Jones',
        address: 'Motel One Manchester-Royal Exchange, 11 - 15 Cross St, Manchester M2 1WD',
        postCode: 'M2 1WD',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1994-12-17T20:01:03+00:00',
        joinDate: `${dateManager.getDateWithOffset(-8, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-8, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        // personal: ['16', '31', '40', '49', '53'],
        // centralTeam: ['16', '31', '40', '49', '53'],
        // organisational: ['16', '31', '40', '49', '53'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '122.65',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'emijon1',
        shopUrl: 'https://meamora.co.uk/search?q=6',
        customerNumber: '6',
        canRegister: true,
        shadow: true,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '7',
        parentId: '5',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 789,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+442306845874',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'CharlotteKittle@mailinator.com',
        firstName: 'Charlotte',
        lastName: 'Kittle',
        address: '33 Pym Walk, Thame OX9 3TA, United Kingdom',
        postCode: 'OX9 3TA',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1964-03-07T14:14:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-17, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-17, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        // personal: ['1', '10', '23', '37', '38', '48', '55'],
        // centralTeam: ['1', '10', '23', '37', '38', '48', '55'],
        // organisational: ['1', '10', '23', '37', '38', '48', '55'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'chakit1',
        shopUrl: 'https://meamora.co.uk/search?q=7',
        customerNumber: '7',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '8',
        parentId: '5',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 784,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+442064814556',
        secondPhoneNumber: '+447962284293',
        secondAddress: null,
        email: 'miakuechly15@mailinator.com',
        firstName: 'Mia',
        lastName: 'Kuechly',
        address: 'Fenwick road, Nottingham NG8 6FY, United Kingdom',
        postCode: 'NG8 6FY',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-03-05T12:14:00+00:00',
        joinDate: `${dateManager.getDateWithOffset(-7, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-7, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'miakue1',
        shopUrl: 'https://meamora.co.uk/search?q=8',
        customerNumber: '8',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '9',
        parentId: '5',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 294,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+442918752455',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'harperMahomes@mailinator.com',
        firstName: 'Harper',
        lastName: 'Mahomes',
        address: 'Nadeem Plaza RoofTop, 172 Dunstable Rd, Luton LU4 8FG, United Kingdom',
        postCode: 'LU4 8FG',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1982-07-01T12:42:17+00:00',
        joinDate: `${dateManager.getDateWithOffset(-8, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-8, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: ['11', '12'],
        // personal: ['9', '14', '30'],
        // centralTeam: ['9', '14', '30', '32'],
        // organisational: ['9', '14', '30', '32'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'harmah1',
        shopUrl: 'https://meamora.co.uk/search?q=9',
        customerNumber: '9',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '10',
        parentId: '5',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 135,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+441875563214',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'evelynbarkley@mailinator.com',
        firstName: 'Evelyn',
        lastName: 'Barkley',
        address: '202 Porchester Rd, Nottingham',
        postCode: 'NG3 6LH',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-09-06T14:20:51+00:00',
        joinDate: `${dateManager.getDateWithOffset(-1, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-1, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'evebar1',
        shopUrl: 'https://meamora.co.uk/search?q=10',
        customerNumber: '10',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '11',
        parentId: '5',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 586,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+449115847752',
        secondPhoneNumber: null,
        secondAddress: '85 Lamphey Road THEALBY DN15 4TL',
        email: 'abigail.mahomes@mailinator.com',
        firstName: 'Abigail',
        lastName: 'Mahomes',
        address: '133 High St N, Dunstable LU6 1JN, United Kingdom',
        postCode: 'LU6 1JN',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-12, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: null,
        paidForKit: false,
        customers: ['13'],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'abimah1',
        shopUrl: 'https://meamora.co.uk/search?q=11',
        customerNumber: '11',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '12',
        parentId: '0',
        uplineLeaderId: '0',
        totals: {
            gv: 1900,
            spa_gv: 1900,
            ov: 1900,
            pv: 479,
        },
        ambassadorLegsCount: 5,
        qualifiedFrontlineLeadersCount: 5,
        activeOnFileDirectEnrollees: 5,
        rankId: '0',
        highestAchievedRankId: '1',
        payRankId: '1',
        directDownline: ['13', '14', '15', '16', '17'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['13', '14', '15', '16', '17'],
        phoneNumber: '+441897452144',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'emilYmacK@mailinator.com',
        firstName: 'Emily',
        lastName: 'Mack',
        address: 'Infirmary Square, Leicester LE1 5WW, United Kingdom',
        postCode: 'LE1 5WW',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1987-08-13T03:10:24+00:00',
        joinDate: `${dateManager.getDateWithOffset(-2, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-2, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: ['13', '14', '15', '16', '17'],
        centralTeam: ['13', '14', '15', '16', '17'],
        organisational: ['13', '14', '15', '16', '17'],
        additionalCommission: '0.00',
        ctb: '371.94',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'emimac1',
        shopUrl: 'https://meamora.co.uk/search?q=12',
        customerNumber: '12',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '13',
        parentId: '12',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 373,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+449118754714',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'Mila.Donald@mailinator.com',
        firstName: 'Mila',
        lastName: 'Donald',
        address: '76 Wigmore Ln, Luton, United Kingdom',
        postCode: 'LU2 8AB',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-7, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-7, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: false,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'mildon1',
        shopUrl: 'https://meamora.co.uk/search?q=13',
        customerNumber: '13',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '14',
        parentId: '12',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 201,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 079 8126 9864',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'OliviaSinclair@armyspy.com',
        firstName: 'Olivia',
        lastName: 'Sinclair',
        address: '58 Mill Lane CORSEWALL',
        postCode: 'DG9 2YX',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-14, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-14, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'olisin1',
        shopUrl: 'https://meamora.co.uk/search?q=14',
        customerNumber: '14',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
    {
        id: '15',
        parentId: '12',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 246,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '8 079 3047 9760',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'LibbyWoodward@armyspy.com',
        firstName: 'Libby',
        lastName: 'Woodward',
        address: '95 Warner Close HASLINGDEN GRANE',
        postCode: 'BB4 6SD',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-7, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-7, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'libwoo1',
        shopUrl: 'https://meamora.co.uk/search?q=15',
        customerNumber: '15',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '16',
        parentId: '12',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 345,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 078 2232 8691',
        secondPhoneNumber: '+44 079 5206 6904',
        secondAddress: '57 Ramsgate Rd WILDHILL AL9 6RN',
        email: 'NicoleCox@rhyta.com',
        firstName: 'Nicole',
        lastName: 'Cox',
        address: '21 Rowland Rd ORFORD',
        postCode: 'IP12 9PG',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-5, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-5, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '67.25',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'niccox1',
        shopUrl: 'https://meamora.co.uk/search?q=16',
        customerNumber: '16',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '17',
        parentId: '12',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 256,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 079 3836 8191',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'TillyCrawford@armyspy.com',
        firstName: 'Tilly',
        lastName: 'Crawford',
        address: '76 Golden Knowes Road FOXWIST GREEN',
        postCode: 'CW7 5AD',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-9, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-9, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '117.80',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'tilcra1',
        shopUrl: 'https://meamora.co.uk/search?q=17',
        customerNumber: '17',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '18',
        parentId: '0',
        uplineLeaderId: '0',
        totals: {
            gv: 3183,
            spa_gv: 3183,
            ov: 3283,
            pv: 532,
        },
        ambassadorLegsCount: 6,
        qualifiedFrontlineLeadersCount: 6,
        activeOnFileDirectEnrollees: 6,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: ['19', '20', '21', '22', '23', '24'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['19', '20', '21', '22', '23', '24'],
        phoneNumber: '8 070 1525 1526',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'LauraGould@armyspy.com',
        firstName: 'Laura',
        lastName: 'Gould',
        address: '33 London Road COMPTON DUNDON',
        postCode: 'TA11 4PJ',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-24, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-24, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: ['19', '20', '21', '22', '23', '24'],
        centralTeam: ['19', '20', '21', '22', '23', '24'],
        organisational: ['19', '20', '21', '22', '23', '24'],
        additionalCommission: '0.00',
        ctb: '190.98',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'laugou1',
        shopUrl: 'https://meamora.co.uk/search?q=18',
        customerNumber: '18',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '19',
        parentId: '18',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 344,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '1 Town Lane SOUTH DUFFIELD',
        postCode: 'YO8 3PG',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'IsabelJones@rhyta.com',
        firstName: 'Isabel',
        lastName: 'Jones',
        address: '+44 070 7142 9404',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-21, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-21, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'isajon1',
        shopUrl: 'https://meamora.co.uk/search?q=19',
        customerNumber: '19',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '20',
        parentId: '18',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 342,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '8 078 4646 6255',
        secondPhoneNumber: '8 079 0839 6067',
        secondAddress: null,
        email: 'FreyaParkin@armyspy.com',
        firstName: 'Freya',
        lastName: 'Parkin',
        address: '1 Lamphey Road THE LEACON TN26 1HJ',
        postCode: 'TN26 1HJ',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-17, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-17, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'frepar1',
        shopUrl: 'https://meamora.co.uk/search?q=20',
        customerNumber: '20',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '21',
        parentId: '18',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 959,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '8 078 2580 5861',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'LaraScott@dayrep.com',
        firstName: 'Lara',
        lastName: 'Scott',
        address: '7 Sloe Lane CRUMLIN NP1 9WE',
        postCode: 'NP1 9WE',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-18, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-18, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'larsco1',
        shopUrl: 'https://meamora.co.uk/search?q=21',
        customerNumber: '21',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '22',
        parentId: '18',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 435,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 070 7351 0120',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'BethanyAndrews@rhyta.com',
        firstName: 'Bethany',
        lastName: 'Andrews',
        address: '91 Old Chapel Road GAYHURST',
        postCode: 'MK16 6QG',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-19, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-19, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'betand',
        shopUrl: 'https://meamora.co.uk/search?q=22',
        customerNumber: '22',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '23',
        parentId: '18',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 523,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 079 3264 0567',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'MillieStorey@rhyta.com',
        firstName: 'Millie',
        lastName: 'Storey',
        address: '96 Bootham Terrace REARSBY LE7 1LE',
        postCode: 'LE7 1LE',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-15, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-15, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'milstor1',
        shopUrl: 'https://meamora.co.uk/search?q=23',
        customerNumber: '23',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '24',
        parentId: '18',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 148,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 077 8190 9333',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'LaurenPope@dayrep.com',
        firstName: 'Lauren',
        lastName: 'Pope',
        address: '84 Monks Way TORRYBURN KY12 1NX',
        postCode: 'KY12 1NX',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-18, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-18, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'laupop1',
        shopUrl: 'https://meamora.co.uk/search?q=24',
        customerNumber: '24',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '25',
        parentId: '0',
        uplineLeaderId: '0',
        totals: {
            gv: 3090,
            spa_gv: 3090,
            ov: 6802,
            pv: 567,
        },
        ambassadorLegsCount: 5,
        qualifiedFrontlineLeadersCount: 5,
        activeOnFileDirectEnrollees: 5,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: ['26', '27', '28', '29', '30', '31', '37', '40'],
        leaderLegs: ['31', '37', '40'],
        qualifiedLeaderBranchIds: [],
        risingStars: ['26', '27', '28', '29', '30'],
        phoneNumber: '8 078 7716 3142',
        secondPhoneNumber: '8 079 0839 6067',
        secondAddress: '48 Merthyr Road BURBAGE',
        postCode: 'LE10 4RN',
        email: 'AaliyahHiggins@teleworm.us',
        firstName: 'Aaliyah',
        lastName: 'Higgins',
        address: '26 Fulford Road PENTIRE TR7 3WQ',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: 'United Kingdom',
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-32, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-32, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: ['26', '27', '28', '29', '30', '31'],
        centralTeam: ['26', '27', '28', '29', '30'],
        organisational: ['26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
        additionalCommission: '0.00',
        ctb: '185.40',
        leadershipBonus: '154.44',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 1,
        vanityUrl: 'aalhig1',
        shopUrl: 'https://meamora.co.uk/search?q=25',
        customerNumber: '25',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '26',
        parentId: '25',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 56,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 078 0403 0454',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'EloiseDennis@rhyta.com',
        firstName: 'Eloise',
        lastName: 'Dennis',
        address: '24 Seaford Road CULQUHIRK',
        postCode: 'DG8 9YJ',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-29, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-29, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'eloden1',
        shopUrl: 'https://meamora.co.uk/search?q=26',
        customerNumber: '26',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N1',
    }, {
        id: '27',
        parentId: '25',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 400,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 078 4891 8726',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'ChloeHawkins@dayrep.com',
        firstName: 'Chloe',
        lastName: 'Hawkins',
        address: '72 Trehafod Road BUCHANTY PH1 3TF',
        postCode: 'PH1 3TF',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-26, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-26, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'chlhaw1',
        shopUrl: 'https://meamora.co.uk/search?q=27',
        customerNumber: '27',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '28',
        parentId: '25',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 546,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 077 6198 7637',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'SiennaMetcalfe@teleworm.us',
        firstName: 'Sienna',
        lastName: 'Metcalfe',
        address: '22 Castledore Road TUNSTALL LA6 3TH',
        postCode: 'LA6 3TH',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-18, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-18, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'siemet1',
        shopUrl: 'https://meamora.co.uk/search?q=28',
        customerNumber: '28',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '29',
        parentId: '25',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 1345,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 070 8368 9975',
        secondPhoneNumber: '+44 070 6827 2583',
        secondAddress: null,
        email: 'TiaLaw@jourrapide.com',
        firstName: 'Tia',
        lastName: 'Law',
        address: '89 Emerson Road KINNADIE',
        postCode: 'AB41 1XG',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-22, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-22, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '67.25',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'tialaw1',
        shopUrl: 'https://meamora.co.uk/search?q=29',
        customerNumber: '29',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '30',
        parentId: '25',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 176,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '8 078 7974 0630',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'AyeshaWilliamson@teleworm.us',
        firstName: 'Ayesha',
        lastName: 'Williamson',
        address: '64 Felix Lane SHOTTISHAM',
        postCode: 'IP12 4BD',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-24, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-24, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'ayewil1',
        shopUrl: 'https://meamora.co.uk/search?q=30',
        customerNumber: '30',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '31',
        parentId: '25',
        uplineLeaderId: '0',
        totals: {
            gv: 3376,
            spa_gv: 3376,
            ov: 3376,
            pv: 1323,
        },
        ambassadorLegsCount: 5,
        qualifiedFrontlineLeadersCount: 5,
        activeOnFileDirectEnrollees: 5,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '1',
        directDownline: ['32', '33', '34', '35', '36'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['32', '33', '34', '35', '36'],
        phoneNumber: '+44 078 7032 8205',
        secondPhoneNumber: null,
        secondAddress: '76 High St THURNING NR20 4SS',
        email: 'SophiaEdwards@rhyta.com',
        firstName: 'Sophia',
        lastName: 'Edwards',
        address: '14 Kent Street CROSSHILL',
        postCode: 'KY5 8FD',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-29, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-29, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: ['32', '33', '34', '35', '36'],
        centralTeam: ['32', '33', '34', '35', '36'],
        organisational: ['32', '33', '34', '35', '36'],
        additionalCommission: '0.00',
        ctb: '142.56',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'sopedw1',
        shopUrl: 'https://meamora.co.uk/search?q=31',
        customerNumber: '31',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '32',
        parentId: '31',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 1259,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 070 1115 0095',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'LucyAkhtar@rhyta.com',
        firstName: 'Lucy',
        lastName: 'Akhtar',
        address: '31 Red Lane ESKDALE GREEN CA19 6PW',
        postCode: 'CA19 6PW',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-26, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-26, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'lucakh1',
        shopUrl: 'https://meamora.co.uk/search?q=32',
        customerNumber: '32',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '33',
        parentId: '31',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 438,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '8 079 1960 1202',
        secondPhoneNumber: '8 070 3174 5237',
        secondAddress: null,
        email: 'MatildaLeonard@dayrep.com',
        firstName: 'Matilda',
        lastName: 'Leonard',
        address: '39 Rowland Rd OSWALDKIRK YO62 3LL',
        postCode: 'YO62 3LL',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-23, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-23, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'matleo1',
        shopUrl: 'https://meamora.co.uk/search?q=33',
        customerNumber: '33',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '34',
        parentId: '31',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 127,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '8 079 1804 9147',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'HollyTucker@dayrep.com',
        firstName: 'Holly',
        lastName: 'Tucker',
        address: '12 Canterbury Road WACTON NR15 6EX',
        postCode: 'NR15 6EX',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-24, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-24, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'holtuc1',
        shopUrl: 'https://meamora.co.uk/search?q=34',
        customerNumber: '34',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '35',
        parentId: '31',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 119,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '070 0509 5447',
        secondPhoneNumber: null,
        secondAddress: null,
        email: 'barbaraJ@mailinator.com',
        firstName: 'Barbara',
        lastName: 'Jones',
        address: '83 Abingdon Road BRAMPTON EN LE MORTHEN',
        postCode: 'S66 5PU',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-18, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-18, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'barjon1',
        shopUrl: 'https://meamora.co.uk/search?q=35',
        customerNumber: '35',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N3',
    }, {
        id: '36',
        parentId: '31',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 110,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 070 1503 0839',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: null,
        email: 'ol.williams@mailinator.com',
        firstName: 'Olivia',
        lastName: 'Williams',
        address: '98 Holgate Rd RAMASAIG IV55 3DP',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-21, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-21, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'oliwil1',
        shopUrl: 'https://meamora.co.uk/search?q=36',
        customerNumber: '36',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N',
    }, {
        id: '37',
        parentId: '25',
        uplineLeaderId: '0',
        totals: {
            gv: 3759,
            spa_gv: 3759,
            ov: 3759,
            pv: 1512,
        },
        ambassadorLegsCount: 5,
        qualifiedFrontlineLeadersCount: 5,
        activeOnFileDirectEnrollees: 5,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: ['38', '39'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['38', '39'],
        phoneNumber: '078 1463 3090',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'G20 8WR',
        email: 'NatashaPower@armyspy.com',
        firstName: 'Natasha',
        lastName: 'Power',
        address: '3 Worthy Lane MARYHILL',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-57, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-57, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'natpow1',
        shopUrl: 'https://meamora.co.uk/search?q=37',
        customerNumber: '37',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '38',
        parentId: '37',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 1202,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 070 3989 0645',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'TS24 9TN',
        email: 'NicoleTucker@teleworm.us',
        firstName: 'Nicole',
        lastName: 'Tucker',
        address: '25 Botley Road MIDDLETON',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-21, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-21, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'nictuc1',
        shopUrl: 'https://meamora.co.uk/search?q=38',
        customerNumber: '38',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '39',
        parentId: '37',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 1045,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+4407715431310',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: null,
        email: 'PhoebeAustin@teleworm.us',
        firstName: 'Phoebe',
        lastName: 'Austin',
        address: '73 Wressle Road PLEAN FK7 7EH',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-8, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-8, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'phoaus1',
        shopUrl: 'https://meamora.co.uk/search?q=39',
        customerNumber: '40',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '40',
        parentId: '25',
        uplineLeaderId: '0',
        totals: {
            gv: 586,
            spa_gv: 586,
            ov: 877,
            pv: 315,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: ['41', '42', '43'],
        leaderLegs: ['43'],
        qualifiedLeaderBranchIds: [],
        risingStars: ['41', '42'],
        phoneNumber: '078 2963 9153',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'NN17 8HB',
        email: 'IsabellaRowe@rhyta.com',
        firstName: 'Isabella',
        lastName: 'Rowe',
        address: '50 Simone Weil Avenue WELDON',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-56, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-56, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'isarow1',
        shopUrl: 'https://meamora.co.uk/search?q=40',
        customerNumber: '40',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '41',
        parentId: '40',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 207,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '07987837129',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: null,
        email: 'MaddisonAnderson@jourrapide.com',
        firstName: 'Maddison',
        lastName: 'Anderson',
        address: '62 Pendwyallt Road BURNSIDE',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-42, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-42, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'matand1',
        shopUrl: 'https://meamora.co.uk/search?q=41',
        customerNumber: '41',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '42',
        parentId: '40',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 64,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 070 3733 0215',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: null,
        email: 'TillyGould@dayrep.com',
        firstName: 'Tilly',
        lastName: 'Gould',
        address: '12 Sloe Lane CRYNANT',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-26, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-26, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'tilgou1',
        shopUrl: 'https://meamora.co.uk/search?q=42',
        customerNumber: '42',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N3',
    }, {
        id: '43',
        parentId: '40',
        uplineLeaderId: '0',
        totals: {
            gv: 291,
            spa_gv: 291,
            ov: 291,
            pv: 201,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: ['44'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['44'],
        phoneNumber: '079 6569 2914',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'PH16 4XY',
        email: 'SkyeRobertson@teleworm.us',
        firstName: 'Skye',
        lastName: 'Robertson',
        address: '10 Prestwick Road INVERHADDEN',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-50, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-50, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'skyrob1',
        shopUrl: 'https://meamora.co.uk/search?q=43',
        customerNumber: '43',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '44',
        parentId: '43',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 90,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: ['46', '47'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['46', '47'],
        phoneNumber: '+44 070 4670 9763',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'LD6 0BP',
        email: 'SofiaDavies@teleworm.us',
        firstName: 'Sofia',
        lastName: 'Davies',
        address: '49 Hull Road PANT-Y-DWR',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-7, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-7, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'sofdav1',
        shopUrl: 'https://meamora.co.uk/search?q=44',
        customerNumber: '44',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N2',
    }, {
        id: '45',
        parentId: '0',
        uplineLeaderId: '0',
        totals: {
            gv: 303,
            spa_gv: 303,
            ov: 303,
            pv: 240,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '077 5286 0719',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'RH13 8WS',
        email: 'BrookeFraser@dayrep.com',
        firstName: 'Brooke',
        lastName: 'Fraser',
        address: '79 Gloddaeth Street BINES GREEN',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-108, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-108, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'brofra1',
        shopUrl: 'https://meamora.co.uk/search?q=45',
        customerNumber: '45',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '46',
        parentId: '45',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 11,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '077 4273 0353',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'AB53 8FG',
        email: 'ElizabethBrady@armyspy.com',
        firstName: 'Elizabeth',
        lastName: 'Brady',
        address: '12 Marlborough Crescent SOUTHEND',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-22, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-22, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'elibra1',
        shopUrl: 'https://meamora.co.uk/search?q=46',
        customerNumber: '46',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N1',
    }, {
        id: '47',
        parentId: '45',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 52,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 078 4564 3700',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: null,
        email: 'PhoebeWaters@teleworm.us',
        firstName: 'Phoebe',
        lastName: 'Waters',
        address: '90 York Road OVINGDEAN',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-15, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-15, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'phowat1',
        shopUrl: 'https://meamora.co.uk/search?q=47',
        customerNumber: '47',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N2',
    }, {
        id: '48',
        parentId: '0',
        uplineLeaderId: '0',
        totals: {
            gv: 418,
            spa_gv: 418,
            ov: 418,
            pv: 302,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: ['49', '50'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['49', '50'],
        phoneNumber: '077 0439 2050',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'LL28 2TD',
        email: 'MelissaByrne@rhyta.com',
        firstName: 'Melissa',
        lastName: 'Byrne',
        address: '53 South Street MOCHDRE',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-103, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-103, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'melbyr1',
        shopUrl: 'https://meamora.co.uk/search?q=48',
        customerNumber: '48',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '49',
        parentId: '48',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 70,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44077 0410 5271',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'IV40 2HW',
        email: 'ChelseaParkin@teleworm.us',
        firstName: 'Chelsea',
        lastName: 'Parkin',
        address: "25 Leicester Road AULT A'CHRUINN",
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-30, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-30, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'chepal1',
        shopUrl: 'https://meamora.co.uk/search?q=49',
        customerNumber: '49',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N2',
    }, {
        id: '50',
        parentId: '48',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 46,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '070 1055 5460',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: null,
        email: 'CerysCurtis@jourrapide.com',
        firstName: 'Cerys',
        lastName: 'Curtis',
        address: '85 Foregate Street COCKERHAM',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-27, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-27, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'cercur1',
        shopUrl: 'https://meamora.co.uk/search?q=50',
        customerNumber: '50',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N3',
    }, {
        id: '51',
        parentId: '0',
        uplineLeaderId: '0',
        totals: {
            gv: 355,
            spa_gv: 355,
            ov: 355,
            pv: 205,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: ['52', '53'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['52', '53'],
        phoneNumber: '+44 077 2150 7984',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'TQ7 5ZH',
        email: 'AlexandraPatel@dayrep.com',
        firstName: 'Alexandra',
        lastName: 'Patel',
        address: '11 Marlborough Crescent SOUTH MILTON',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-98, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-98, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'alepat1',
        shopUrl: 'https://meamora.co.uk/search?q=51',
        customerNumber: '51',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '52',
        parentId: '51',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 60,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '078 2294 1632',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'IV36 8ZZ',
        email: 'FreyaBanks@rhyta.com',
        firstName: 'Freya',
        lastName: 'Banks',
        address: '85 Monks Way TOMNAMOON',
        country: COUNTRY_SELECT_OPTIONS.ie,
        region: REGION_OPTIONS.ie,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-38, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-38, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'freban1',
        shopUrl: 'https://meamora.co.uk/search?q=52',
        customerNumber: '52',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N1',
    }, {
        id: '53',
        parentId: '51',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 90,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '077 2428 4067',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'PA43 7AZ',
        email: 'MadeleineHudson@teleworm.us',
        firstName: 'Madeleine',
        lastName: 'Hudson',
        address: '10 North Road NERIBY',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-21, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-21, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'madhud1',
        shopUrl: 'https://meamora.co.uk/search?q=53',
        customerNumber: '53',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N1',
    }, {
        id: '54',
        parentId: '0',
        uplineLeaderId: '0',
        totals: {
            gv: 619,
            spa_gv: 619,
            ov: 619,
            pv: 405,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '2',
        payRankId: '2',
        directDownline: ['55', '56'],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: ['55', '56'],
        phoneNumber: '+44 070 5128 0686',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'HR1 6JA',
        email: 'LillyRoberts@rhyta.com',
        firstName: 'Lilly',
        lastName: 'Roberts',
        address: '14 Ermin Street YARKHILL',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-95, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-95, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'lilrob1',
        shopUrl: 'https://meamora.co.uk/search?q=54',
        customerNumber: '54',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    }, {
        id: '55',
        parentId: '54',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 10,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '078 8620 0294',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'S42 6AS',
        email: 'AnnaHolland@jourrapide.com',
        firstName: 'Anna',
        lastName: 'Holland',
        address: '91 Folkestone Road WINGERWORTH',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-35, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-35, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'annhol1',
        shopUrl: 'https://meamora.co.uk/search?q=55',
        customerNumber: '55',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'N3',
    }, {
        id: '56',
        parentId: '54',
        uplineLeaderId: '0',
        totals: {
            gv: 0,
            spa_gv: 0,
            ov: 0,
            pv: 204,
        },
        ambassadorLegsCount: 0,
        qualifiedFrontlineLeadersCount: 0,
        activeOnFileDirectEnrollees: 0,
        rankId: '0',
        highestAchievedRankId: '0',
        payRankId: '0',
        directDownline: [],
        leaderLegs: [],
        qualifiedLeaderBranchIds: [],
        risingStars: [],
        phoneNumber: '+44 070 6028 7099',
        secondPhoneNumber: null,
        secondAddress: null,
        postCode: 'TW20 7RS',
        email: 'LibbyLeach@rhyta.com',
        firstName: 'Libby',
        lastName: 'Leach',
        address: '95 Whitchurch Road ENGLEFIELD GREEN',
        country: COUNTRY_SELECT_OPTIONS.gb,
        region: REGION_OPTIONS.gb,
        county: null,
        town: null,
        birthDate: '1991-07-11T09:54:40+00:00',
        joinDate: `${dateManager.getDateWithOffset(-32, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        kitPurchaseDate: `${dateManager.getDateWithOffset(-32, mockedDateManager.getCurrentDate(), dateManager.getDayTimeFormatUtc(), 'day')}`,
        paidForKit: true,
        customers: [],
        personal: [],
        centralTeam: [],
        organisational: [],
        additionalCommission: '0.00',
        ctb: '0.00',
        leadershipBonus: '0.00',
        totalSales: '0.00',
        agreedToTermsAndConditions: true,
        ambassadorsCountsByActivityLevel: null,
        newAmbassadorsPersonal: 0,
        newAmbassadorsInCentralTeam: 0,
        newAmbassadorsOrganizational: 0,
        starterKit: 1,
        qualifiedOrgLeaderCount: 0,
        vanityUrl: 'liblea1',
        shopUrl: 'https://meamora.co.uk/search?q=56',
        customerNumber: '56',
        canRegister: true,
        shadow: false,
        bankDetails: {
            bankSortCode: null,
            bankAccountNumber: null,
            bankAccountName: null,
        },
        lexisNexisStatus: LEXIS_NEXIS_STATUS.pass,
        hidden: false,
        aOFStatus: 'Y',
    },
];

const enrollee: (id: string) => ResolverEnrolleeType = (id) => enrollees
    .find((item: ResolverEnrolleeType) => item.id === id)!;

const directDownline: (ids: string[], limit, offset, aofFilter) =>
    ResolverEnrolleeType[] = (ids, limit, offset, aofFilter) => {
        const data = ids.map((leg) => enrollee(leg));
        return data
            .filter((item) => !aofFilter || item.aOFStatus === aofFilter)
            .splice(offset, limit);
    };

const personalRecruits: (ids: string[], from?, to?) =>
    ResolverEnrolleeType[] = (ids, from = null, to = null) => {
        let data = ids.map((leg) => enrollee(leg)).filter((leg) => leg.totals.pv >= 150);

        if (from) {
            data = data.filter((item) => dateManager.isAfter(item.kitPurchaseDate, from));
        }

        if (to) {
            data = data.filter((item) => dateManager.isBefore(item.kitPurchaseDate, to));
        }

        return data;
    };

const getEnrolleePersonalRecruits: (id) => number = (id: string) => {
    const enrolleeItem: ResolverEnrolleeType = enrollee(id);

    if (!enrolleeItem) {
        return 0;
    }

    const recruits = personalRecruits(enrolleeItem.directDownline);

    return recruits.length;
};

const getRunTotals = (id): RunTotalType[] => {
    const enrolleeItem: ResolverEnrolleeType = enrollee(id);
    const res: RunTotalType[] = [];

    const diff: number = dateManager.getDifference(enrolleeItem.joinDate, mockedDateManager.getCurrentDate(), 'month');

    for (let index = 0; index <= diff; index++) {
        const offset = -(diff - index);
        let pv: number = 0;
        if (index === diff) {
            pv = enrolleeItem.totals.pv;
        } else if (index === 0) {
            pv = Number((enrolleeItem.totals.pv * 0.31).toFixed(2));
        } else {
            const a = Number(Number(`0.${index}`).toFixed(2));

            pv = Number((enrolleeItem.totals.pv / a).toFixed(2));
        }

        res.push({
            enrolleeMetrics: {
                pv,
            },
            from: dateManager.getLastDayOfMonth(
                offset,
                dateManager.getDateFormat(),
                mockedDateManager.getCurrentDate(),
            ),
            id: index.toString(),
            runId: index.toString(),
            to: dateManager.getFirstDayOfMonth(
                offset,
                dateManager.getDateFormat(),
                mockedDateManager.getCurrentDate(),
            ),
        });
    }

    return res;
};

export {
    enrollee, enrollees, directDownline, personalRecruits,
    getEnrolleePersonalRecruits, getRunTotals,
};
