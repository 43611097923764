// import FunctionalityManager from '@/utils/functionality-manager';
import { MILESTONE_TYPE } from '@/modules/Dashboard/constants';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { PowerSellerClubType } from './types';

export default class GetPowerSellerClubQuery extends
    AbstractQueryResource<GetPowerSellerClubResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                powerSellerAnnual: milestones(filter: { period: 0, type: "${MILESTONE_TYPE.powerSellerAnnual}" }) {
                    type,
                    category,
                    key,
                    achieved,
                    achievedAt,
                    unachievable,
                    startDate,
                    endDate,
                    state {
                        metric,
                        value,
                    },
                },
                powerSellerQuarterly: milestones(filter: { period: 0, type: "${MILESTONE_TYPE.powerSellerQuarterly}" }) {
                    type,
                    category,
                    key,
                    achieved,
                    achievedAt,
                    unachievable,
                    startDate,
                    endDate,
                    state {
                        metric,
                        value,
                    },
                },
                powerSellerMonthly: milestones(filter: { period: 0, type: "${MILESTONE_TYPE.powerSellerMonthly}" }) {
                    type,
                    category,
                    key,
                    achieved,
                    achievedAt,
                    unachievable,
                    startDate,
                    endDate,
                    state {
                        metric,
                        value,
                    },
                },
            },
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getPowerSellerClub';
    }
}

export type GetPowerSellerClubResultType = {
    profile: PowerSellerClubType;
}
