import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { FileType } from '@/components/fileArea/types';
import componentsControls from '@/modules/ComponentsControls';
import {
    AssetType, FlagFullType, RegisterAmbassadorType, RegisterHostessType, UploadProfilePictureType,
} from '@/api/graphQL/graphNodes/types';
import { REGISTERED_MESSAGE } from '@/utils/messages/formValidation';
import { RegisterAmbassadorParamsType } from '@/api/graphQL/graphNodes/RegisterAmbassadorQuery';
import addressSelect from '@/modules/AddressSelect';
import dateManager from '@/utils/time';
import { initialHostessRegistrationData, initialRegistrationData } from './defaults';
import { gbLabels } from '../labels';
import {
    registrationFormValidation, hostessRegistrationFormValidation,
} from './services/registrationFormValidation';
import RegisterRepository from './services/registerRepository';
import { LabelType, ObjectPropertyType } from '../types';
import { LEXIS_NEXIS_STATUS } from './constants';
import { TERMS_AND_CONDITIONS_TYPE } from '../Profile/constants';
import { COUNTRY_SELECT_OPTIONS, FORM_ERROR_TOOLTIP, JOAH_FLAG_KEY } from '../constants';
import { defaultFlag } from '../defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'register',
})
@AutoMutations
export class Register extends VuexModule {
    private formData: RegisterAmbassadorType = initialRegistrationData();

    private labels: LabelType = { ...gbLabels() };

    private canRegister: boolean = true;

    private userId: string|null = null;

    private profilePicture: UploadProfilePictureType = { link: '', context: [] };

    private formErrors: ErrorType = {};

    private loading: boolean = true;

    private termsAndConditions: AssetType|null = null;

    private termsDialogOpen: boolean = false;

    private loadingInBackground: boolean = false;

    private customerNumber: string|null = null;

    private imageDialogOpen: boolean = false;

    private profilePicUpload: FileType|null = null;

    private cameraDialogOpen: boolean = false;

    private lexisNexisStatus: string|null = null;

    private hostessFormData: RegisterHostessType = initialHostessRegistrationData();

    private hostessFormErrors: ErrorType = {};

    private displayTooltip: boolean = false;

    private birthDate: string = '';

    private joah: FlagFullType = defaultFlag();

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    toggleTermsDialogOpen() {
        this.termsDialogOpen = !this.termsDialogOpen;
    }

    @Mutation
    public setLabels(payload: LabelType) {
        this.labels = { ...payload };
    }

    @Mutation
    public setFormData(data: RegisterAmbassadorType) {
        this.formData = { ...initialRegistrationData(), ...data };
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors[payload.key] = payload.val;
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public setCustomerNumber(val: string|null) {
        this.customerNumber = val;
    }

    @Mutation
    public setTermsAndConditions(val: AssetType|null) {
        this.termsAndConditions = val;
    }

    @Mutation
    public setCanRegister(val: boolean) {
        this.canRegister = val;
    }

    @Mutation
    public setUserId(val: string|null) {
        this.userId = val;
    }

    @Mutation
    public clearFormData() {
        this.formData = { ...initialRegistrationData() };
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setCameraDialogOpen(val: boolean) {
        this.cameraDialogOpen = val;
    }

    public setImageDialogOpen(val: boolean) {
        this.imageDialogOpen = val;
    }

    @Mutation
    public setProfilePicture(val: UploadProfilePictureType) {
        this.profilePicture = val;
    }

    @Mutation
    public setTermsCheckbox(val: boolean) {
        this.formData.termsChecked = val;
    }

    @Mutation
    public setLexisNexisStatus(val: string|null) {
        this.lexisNexisStatus = val;
    }

    @Mutation
    public setHostessFormData(data: RegisterHostessType) {
        this.hostessFormData = { ...initialHostessRegistrationData(), ...data };
    }

    @Mutation
    public clearHostessFormData() {
        this.hostessFormData = { ...initialHostessRegistrationData() };
    }

    @Mutation
    public clearHostessFormErrors() {
        this.hostessFormErrors = {};
    }

    @Mutation
    public removeHostessFormError(key) {
        const { hostessFormErrors } = this;
        delete hostessFormErrors[key];
        this.hostessFormErrors = { ...hostessFormErrors };
    }

    @Mutation
    public setHostessFormError(payload: ObjectPropertyType) {
        const { key, val } = payload;
        this.hostessFormErrors[key] = val;
    }

    @Mutation
    public setHostessFormErrors(errors) {
        this.hostessFormErrors = { ...errors };
    }

    @Mutation
    public setHostessTermsCheckbox(val: boolean) {
        this.hostessFormData.termsChecked = val;
    }

    @Mutation
    public setBirthDate(val: string = '') {
        this.birthDate = val;
    }

    @Mutation
    public setJoah(val: Partial<FlagFullType>) {
        this.joah = { ...defaultFlag(), ...val };
    }

    @Action()
    public validateInvitationForm(params: {formData, passwordCheck}) {
        const { formData, passwordCheck } = params;
        this.clearFormErrors();
        const formErrors = registrationFormValidation(formData, passwordCheck);
        formErrors.forEach((error) => this.setFormError(error));
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public async registerAmbassador(payload: {token: string, passwordCheck: boolean}) {
        try {
            const { token, passwordCheck } = payload;

            const formData = {
                ...this.formData,
                birthDate: this.birthDate,
                addressLookup: addressSelect.addressId,
                flags: [{
                    key: this.joah.key,
                    value: this.joah.value,
                }],
            };

            this.validateInvitationForm({ passwordCheck, formData });

            if (Object.keys(this.formErrors).length === 0) {
                this.setLoadingInBackground(true);

                const formattedFormData: RegisterAmbassadorParamsType = {
                    register: {
                        ...formData,
                    },
                };

                const res = await RegisterRepository
                    .registerAmbassador(formattedFormData, token);

                this.setLexisNexisStatus(res.registerAmbassador.lexisNexisStatus);
                if (res.registerAmbassador.lexisNexisStatus === LEXIS_NEXIS_STATUS.pass) {
                    componentsControls.showSuccessMessage({ message: REGISTERED_MESSAGE });
                }
                this.clearFormData();
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
            throw e;
        } finally {
            this.setLoadingInBackground(false);
        }
    }

    @Action()
    public async getUserData(token: string) {
        try {
            this.setLoading(true);
            const reqParams = {
                type: TERMS_AND_CONDITIONS_TYPE.ambassador,
            };
            const response = await RegisterRepository.checkCustomerNumber(reqParams, token);

            const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                customerNumber, canRegister, userId, lexisNexisStatus, profilePicture, flags,
                ...data
            } = response.profile;

            // flags
            // const indexOfFlag = flags.map((item) => item.key).indexOf(JOAH_FLAG_KEY);

            // if (indexOfFlag >= 0) {
            //     this.setJoah(flags[indexOfFlag]);
            // } else {
            //     this.setJoah();
            // }
            this.setJoah({ key: JOAH_FLAG_KEY, value: true });

            // formData
            this.setFormData({
                ...initialRegistrationData(),
                ...ObjectProcessor.removeEmptyProperties(data),
            });

            const { birthDate } = response.profile;

            if (birthDate) {
                this.setBirthDate(dateManager.getDateTime(birthDate, 'DD-MM-YYYY'));
            }

            this.setCustomerNumber(customerNumber);
            this.setUserId(userId);
            this.setCanRegister(canRegister);
            this.setLexisNexisStatus(lexisNexisStatus);

            if (response.termsAndConditions) {
                this.setTermsAndConditions(response.termsAndConditions.content);
            }

            if (profilePicture !== null) {
                this.setProfilePicture(profilePicture);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getHostessRegistration(token: string) {
        try {
            this.setLoading(true);

            const params = {
                type: TERMS_AND_CONDITIONS_TYPE.hostess,
            };

            const result = await RegisterRepository
                .getHostessRegistration(params, token);
            const { shadow, ...data } = result.profile;
            const resultData = ObjectProcessor.removeEmptyProperties(data);
            this.setHostessFormData({ ...resultData });

            this.setCanRegister(shadow);

            if (result.termsAndConditions) {
                this.setTermsAndConditions(result.termsAndConditions.content);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async registerHostess(token: string) {
        const { addressId } = addressSelect;
        const hostessFormData: RegisterHostessType = {
            ...this.hostessFormData,
            birthDate: this.birthDate,
            addressLookup: addressId === '' ? null : addressId,
        };

        this.validateHostessForm(hostessFormData);

        if (Object.keys(this.hostessFormErrors).length === 0) {
            this.setLoadingInBackground(true);

            try {
                await RegisterRepository.registerHostess({ register: this.hostessFormData }, token);
                componentsControls.showSuccessMessage({ message: REGISTERED_MESSAGE });
                this.clearHostessFormData();
                addressSelect.setAddress();
                addressSelect.clearOptions();
            } catch (e) {
                this.displayFormErrorsTooltip();
                const errors = ErrorsProcessor.process(e);
                this.setHostessFormErrors(errors.form);
                throw e;
            } finally {
                this.setLoadingInBackground(false);
            }
        } else {
            this.displayFormErrorsTooltip();
            throw new Error('Form is invalid');
        }
    }

    @Action()
    public async getCustomerRegistration(token: string) {
        try {
            const result = await RegisterRepository
                .getCustomerRegistration(TERMS_AND_CONDITIONS_TYPE.customer, token);

            const data = ObjectProcessor.removeEmptyProperties(result.profile);
            this.setHostessFormData({ country: COUNTRY_SELECT_OPTIONS.gb, ...data });

            if (result.termsAndConditions) {
                this.setTermsAndConditions(result.termsAndConditions.content);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async registerCustomer(token: string) {
        const { addressId } = addressSelect;
        const hostessFormData: RegisterHostessType = {
            ...this.hostessFormData,
            birthDate: this.birthDate,
            addressLookup: addressId === '' ? null : addressId,
        };

        this.validateHostessForm(hostessFormData);

        if (Object.keys(this.hostessFormErrors).length === 0) {
            this.setLoadingInBackground(true);

            try {
                await RegisterRepository.registerCustomer(
                    { register: hostessFormData },
                    token,
                );
                componentsControls.showSuccessMessage({ message: REGISTERED_MESSAGE });
                this.clearHostessFormData();
                addressSelect.setAddress();
                addressSelect.clearOptions();
            } catch (e) {
                this.displayFormErrorsTooltip();
                const errors = ErrorsProcessor.process(e);
                this.setHostessFormErrors(errors.form);
                throw e;
            } finally {
                this.setLoadingInBackground(false);
            }
        } else {
            this.displayFormErrorsTooltip();
            throw new Error('Form is invalid');
        }
    }

    @Action()
    public validateHostessForm(hostessFormData: RegisterHostessType) {
        this.clearHostessFormErrors();
        const formErrors = hostessRegistrationFormValidation(hostessFormData);
        formErrors
            .forEach((error) => this.setHostessFormError({ key: error.key, val: error.val }));
    }
}

export default getModule(Register);
