import { ProfileAmbassadorResponseType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class UpdateProfileQuery extends AbstractQueryResource<UpdateProfileResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($profile: ProfileInput) {
            updateProfile(profile: $profile) {
                firstName,
                lastName,
                phoneNumber,
                email,
                address,
                secondAddress,
                birthDate,
                postCode,
                town,
                county,
                country,
                businessDetails {
                    businessName,
                    businessAddress
                }
                flags {
                    key,
                    value,
                    type,
                    label,
                    description,
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'updateProfile';
    }
}

export type UpdateProfileResultType = {
    updateProfile: ProfileAmbassadorResponseType;
}
