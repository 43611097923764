import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { MILESTONE_KEY, MILESTONE_STATE_METRIC, MILESTONE_TYPE } from '@/modules/Dashboard/constants';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import { defaultsMilestones } from '../defaults';
import { ResolverMilestonePropsType, ResolverMilestoneType } from '../types';

const dayTimeFormatWithoutTime = 'YYYY-MM-DD';

const currentDate = mockedDateManager.getCurrentDate(dateManager.getDateFormat());
const currentDateWithOffset = dateManager.getDateWithOffset(18, currentDate);

const milestonesList: ResolverMilestoneType[] = [
    {
        enrolleeId: '0',
        period: 0,
        milestones: [
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_boost_uk',
                category: 'kit_boost',
                achieved: true,
                achievedAt: '2022-07-31T23:59:59+00:00',
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_boost_non_uk',
                category: 'kit_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-25, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_refund_uk',
                category: 'kit_refund',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-25, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_refund_non_uk',
                category: 'kit_refund',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'business_boost_uk',
                category: 'business_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'business_boost_non_uk',
                category: 'business_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'double_promotion',
                category: 'double_promotion',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'bronze_2_tertile',
                category: 'bronze',
                achieved: true,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'silver_2_tertile',
                category: 'silver',
                achieved: true,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'gold_2_tertile',
                category: 'gold',
                achieved: false,
                achievedAt: null,
                unachievable: true,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-25, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.travel,
                key: MILESTONE_KEY.fullIncentiveCriteria,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: true,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.consistentLeaderWildcard,
                category: MILESTONE_KEY.consistentLeaderWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.entries,
                        value: '47',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.newLeaderWildcard,
                category: MILESTONE_KEY.newLeaderWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.entries,
                        value: '98',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.activityWildcard,
                category: MILESTONE_KEY.activityWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.entries,
                        value: '98',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelSelling,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '98',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelRecruiting,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '152368',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.travel,
                key: MILESTONE_KEY.travelPromoting,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelLeadership,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '5',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(-20, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-45, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp1,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(0, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp2,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(1, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(0, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp3,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(2, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepParent,
                key: MILESTONE_KEY.stepUp,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.validCount,
                    value: '6',
                }, {
                    metric: MILESTONE_STATE_METRIC.achievedCount,
                    value: '5',
                }],
                endDate: `${dateManager.getDateWithOffset(2, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: '',
            },
            {
                type: MILESTONE_TYPE.powerSellerAnnual,
                key: MILESTONE_KEY.powerSellerAnnual,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '500000',
                }],
                endDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-12-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-01-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller1Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '95000',
                }],
                endDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-03-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-01-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller2Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-06-30 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-04-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller3Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '500000',
                }],
                endDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-09-30 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-07-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller4Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-12-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDate, 'YYYY')}-10-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerMonthly,
                key: MILESTONE_KEY.powerSellerMonthly,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '50000',
                }],
                endDate: `${dateManager.getLastDayOfMonth(-1, 'YYYY-MM-DD', currentDate)} 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getFirstDayOfMonth(-1, 'YYYY-MM-DD', currentDate)} 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.uniqueOrderBonus,
                key: MILESTONE_KEY.uniqueOrderBonus,
                category: MILESTONE_KEY.uniqueOrderBonus,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '15000',
                }, {
                    metric: MILESTONE_STATE_METRIC.uniquePurchaserCount,
                    value: '3',
                }],
                endDate: `${dateManager.getLastDayOfMonth(-1, 'YYYY-MM-DD', currentDate)} 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getFirstDayOfMonth(-1, 'YYYY-MM-DD', currentDate)} 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
        ],
    },
    {
        enrolleeId: '0',
        period: 1,
        milestones: [
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_boost_uk',
                category: 'kit_boost',
                achieved: true,
                achievedAt: '2022-06-21T23:59:59+00:00',
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(5, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_boost_non_uk',
                category: 'kit_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_refund_uk',
                category: 'kit_refund',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_refund_non_uk',
                category: 'kit_refund',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'business_boost_uk',
                category: 'business_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'business_boost_non_uk',
                category: 'business_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(5, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'double_promotion',
                category: 'double_promotion',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'bronze_2_tertile',
                category: 'bronze',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(5, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'silver_2_tertile',
                category: 'silver',
                achieved: true,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'gold_2_tertile',
                category: 'gold',
                achieved: false,
                achievedAt: null,
                unachievable: true,
                state: [],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.travel,
                key: MILESTONE_KEY.fullIncentiveCriteria,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.consistentLeaderWildcard,
                category: MILESTONE_KEY.consistentLeaderWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(10, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.newLeaderWildcard,
                category: MILESTONE_KEY.newLeaderWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.activityWildcard,
                category: MILESTONE_KEY.activityWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelSelling,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '3562',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelRecruiting,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.travel,
                key: MILESTONE_KEY.travelPromoting,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '81652',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelLeadership,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '2345',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp1,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(-1, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-3, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp2,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(0, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp3,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(1, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-1, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepParent,
                key: MILESTONE_KEY.stepUp,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.validCount,
                    value: '6',
                }, {
                    metric: MILESTONE_STATE_METRIC.achievedCount,
                    value: '5',
                }],
                endDate: '',
                startDate: '',
            },
            {
                type: MILESTONE_TYPE.powerSellerAnnual,
                key: MILESTONE_KEY.powerSellerAnnual,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '500000',
                }],
                endDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-12-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-01-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller1Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '95000',
                }],
                endDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-03-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-01-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller2Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-06-30 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-04-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller3Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '500000',
                }],
                endDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-09-30 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-07-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller4Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-12-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(0, currentDate, 'YYYY')}-10-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerMonthly,
                key: MILESTONE_KEY.powerSellerMonthly,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '50000',
                }],
                endDate: `${dateManager.getLastDayOfMonth(0, 'YYYY-MM-DD', currentDate)} 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getFirstDayOfMonth(0, 'YYYY-MM-DD', currentDate)} 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.uniqueOrderBonus,
                key: MILESTONE_KEY.uniqueOrderBonus,
                category: MILESTONE_KEY.uniqueOrderBonus,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '15000',
                }, {
                    metric: MILESTONE_STATE_METRIC.uniquePurchaserCount,
                    value: '3',
                }],
                endDate: `${dateManager.getLastDayOfMonth(0, 'YYYY-MM-DD', currentDate)} 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getFirstDayOfMonth(0, 'YYYY-MM-DD', currentDate)} 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
        ],
    },
    {
        enrolleeId: '0',
        period: 2,
        milestones: [
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_boost_uk',
                category: 'kit_boost',
                achieved: true,
                achievedAt: '2022-05-16T23:59:59+00:00',
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_boost_non_uk',
                category: 'kit_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(42, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_refund_uk',
                category: 'kit_refund',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(42, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'kit_refund_non_uk',
                category: 'kit_refund',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'business_boost_uk',
                category: 'business_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(37, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'business_boost_non_uk',
                category: 'business_boost',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(42, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.quickStart,
                key: 'double_promotion',
                category: 'double_promotion',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(37, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'bronze_2_tertile',
                category: 'bronze',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'silver_2_tertile',
                category: 'silver',
                achieved: true,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.bdp,
                key: 'gold_2_tertile',
                category: 'gold',
                achieved: true,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(37, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.travel,
                key: MILESTONE_KEY.fullIncentiveCriteria,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(42, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.consistentLeaderWildcard,
                category: MILESTONE_KEY.consistentLeaderWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.newLeaderWildcard,
                category: MILESTONE_KEY.newLeaderWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.activityWildcard,
                category: MILESTONE_KEY.activityWildcard,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelSelling,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '16253',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelRecruiting,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '145',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.travel,
                key: MILESTONE_KEY.travelPromoting,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [
                    {
                        metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                        value: '2695',
                    },
                ],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.wildcard,
                key: MILESTONE_KEY.travelLeadership,
                category: 'travel_incentive',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(47, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(17, currentDate, dayTimeFormatWithoutTime)} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp1,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(0, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(-2, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp2,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(1, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(0, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepChild,
                key: MILESTONE_KEY.stepUp3,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '3000',
                }],
                endDate: `${dateManager.getDateWithOffset(18, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(0, currentDateWithOffset, dayTimeFormatWithoutTime, 'M')} 14:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.stepParent,
                key: MILESTONE_KEY.stepUp,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.validCount,
                    value: '6',
                }, {
                    metric: MILESTONE_STATE_METRIC.achievedCount,
                    value: '5',
                }],
                endDate: '',
                startDate: '',
            },
            {
                type: MILESTONE_TYPE.powerSellerAnnual,
                key: MILESTONE_KEY.powerSellerAnnual,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '500000',
                }],
                endDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-12-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-01-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller1Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '95000',
                }],
                endDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-03-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-01-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller2Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-06-30 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-04-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller3Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '500000',
                }],
                endDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-09-30 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-07-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerQuarterly,
                key: MILESTONE_KEY.powerSeller4Quarter,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [],
                endDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-12-31 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getDateWithOffset(1, currentDate, 'YYYY')}-10-01 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.powerSellerMonthly,
                key: MILESTONE_KEY.powerSellerMonthly,
                category: '',
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '50000',
                }],
                endDate: `${dateManager.getLastDayOfMonth(1, 'YYYY-MM-DD', currentDate)} 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getFirstDayOfMonth(1, 'YYYY-MM-DD', currentDate)} 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
            {
                type: MILESTONE_TYPE.uniqueOrderBonus,
                key: MILESTONE_KEY.uniqueOrderBonus,
                category: MILESTONE_KEY.uniqueOrderBonus,
                achieved: false,
                achievedAt: null,
                unachievable: false,
                state: [{
                    metric: MILESTONE_STATE_METRIC.total,
                    value: '15000',
                }, {
                    metric: MILESTONE_STATE_METRIC.uniquePurchaserCount,
                    value: '3',
                }],
                endDate: `${dateManager.getLastDayOfMonth(1, 'YYYY-MM-DD', currentDate)} 23:59${dateManager.getCurrentDate('ZZ')}`,
                startDate: `${dateManager.getFirstDayOfMonth(1, 'YYYY-MM-DD', currentDate)} 00:00${dateManager.getCurrentDate('ZZ')}`,
            },
        ],
    },
];

const milestone: (enrolleeId: string, params: Partial<ResolverMilestonePropsType>)
 => MilestoneType[] = (enrolleeId, params = {}) => {
     const id = '0';
     const { period = 0, type } = params;
     const milestones = milestonesList
         .find((item) => item.enrolleeId === id && item.period === period);

     const res = milestones ? milestones.milestones : defaultsMilestones();

     return type ? res.filter((item) => item.type === type) : res;
 };

export { milestone };
