import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { MilestoneType, RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetDashboardStatsResultType } from '@/api/graphQL/graphNodes/GetDashboardStatsQuery';
import dashboardRepository from './services/dashboardRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'dashboardStats',
})
@AutoMutations
export class DashboardStats extends VuexModule {
    private stats: RankRequirementsType[] = [];

    private selectedRun: string = '';

    private loading: boolean = false;

    private loaded: boolean = false;

    private ranks: RankType[] = [];

    private commissionRunRankId: string = '';

    private commissionRunPayRankId: string = '';

    private uniqueOrders: MilestoneType[] = [];

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setSelectedRun(val: string = '') {
        this.selectedRun = val;
    }

    @Mutation
    public setStats(val: RankRequirementsType[]) {
        this.stats = [...val];
    }

    @Mutation
    public setUniqueOrders(val: MilestoneType[]) {
        this.uniqueOrders = [...val];
    }

    @Mutation
    public setRanks(val: RankType[]) {
        this.ranks = [...val.sort((a, b) => a.power - b.power)];
    }

    @Mutation
    public setCommissionRunRankId(val: string = '') {
        this.commissionRunRankId = val;
    }

    @Mutation
    public setCommissionRunPayRankId(val: string = '') {
        this.commissionRunPayRankId = val;
    }

    @Action()
    public async getStats() {
        try {
            this.setLoading(true);
            const res: GetDashboardStatsResultType = await dashboardRepository
                .getDataStats({
                    runId: this.selectedRun,
                });

            const { profile, ranks } = res;

            this.setStats(profile.stats);
            this.setUniqueOrders(profile.uniqueOrders);

            if (profile.rank) {
                this.setCommissionRunRankId(profile.rank.id);
            }

            if (profile.payRank) {
                this.setCommissionRunPayRankId(profile.payRank.id);
            }

            this.setRanks(ranks);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(DashboardStats);
