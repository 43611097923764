export const MILESTONE_TYPE = {
    quickStart: 'quick_start',
    bdp: 'bdp',
    travel: 'travel',
    wildcard: 'wildcard',
    stepChild: 'step_child',
    stepParent: 'step_parent',
    powerSellerAnnual: 'power_seller_annual',
    powerSellerQuarterly: 'power_seller_quarterly',
    powerSellerMonthly: 'power_seller_monthly',
    payRankConsistency: 'pay_rank_consistency',
    uniqueOrderBonus: 'unique_order_bonus',
};

export const MILESTONE_STATE_METRIC = {
    leaderboardPoints: 'leaderboard_points',
    entries: 'entries',
    validCount: 'validCount',
    achievedCount: 'achievedCount',
    total: 'total',
    uniquePurchaserCount: 'unique_purchaser_count',

};

export const MILESTONE_CATEGORY = {
    travel: 'travel_incentive',
};

export const MILESTONE_KEY = {
    travelSelling: 'travel_selling',
    travelRecruiting: 'travel_recruiting',
    travelPromoting: 'travel_promoting',
    travelLeadership: 'travel_leadership',
    consistentLeaderWildcard: 'consistent_leader_wildcard',
    newLeaderWildcard: 'new_leader_wildcard',
    activityWildcard: 'activity_wildcard',
    fullIncentiveCriteria: 'full_incentive_criteria',
    fakeTravel: 'fake_travel_milestone',
    stepUp1: 'step_1',
    stepUp2: 'step_2',
    stepUp3: 'step_3',
    stepUp: 'step_up',
    powerSellerAnnual: 'power_seller_annual',
    powerSeller1Quarter: 'power_seller_1_quarter',
    powerSeller2Quarter: 'power_seller_2_quarter',
    powerSeller3Quarter: 'power_seller_3_quarter',
    powerSeller4Quarter: 'power_seller_4_quarter',
    powerSellerMonthly: 'power_seller_monthly',
    uniqueOrderBonus: 'unique_order_bonus',
};

export const MILESTONE_LEVEL_STATE = {
    gold: 'gold',
    silver: 'silver',
    bronze: 'bronze',
};

export const STEP_UP_MILESTONE_KEY = {
    numberOfNewStylists: 'numberOfNewStylists',
    numberOfNewQualifiedStylists: 'numberOfNewQualifiedStylists',
    stepUpEarnings: 'stepUpEarnings',
};

export const AWARD_KEY = {
    topTravelSeller: 'top_travel_seller',
    topTravelRecruiter: 'top_travel_recruiter',
    topTravelLeader: 'top_travel_leader',
    topTravelOverall: 'top_travel_overall',
};

export const MILESTONES_TOOLTIP_TYPES = {
    travel: 'travel',
    wildcard: 'wildcard',
    business: 'business',
    quick: 'quick',
};

export const BUSINESS_DEVELOPMENT_TYPES = {
    silver: 'silver',
    bronze: 'bronze',
    gold: 'gold',
};
