
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboardStats from '@/modules/Dashboard/stats';
import { MilestoneType, RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import { defaultMilestone } from '@/modules/Dashboard/defaults';
import { MILESTONE_STATE_METRIC } from '@/modules/Dashboard/constants';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import CurrencyPrefix from '@/utils/currencyPrefix';

@Component
export default class UniqueOrders extends Vue {
    @Get(dashboardStats) private uniqueOrders!: MilestoneType[];

    @Get(dashboardStats) private stats!: RankRequirementsType[];

    @Get(dashboardStats) private loading!: boolean;

    get milestone(): MilestoneType {
        const { uniqueOrders } = this;
        if (uniqueOrders.length > 0) {
            return uniqueOrders[0];
        }

        return defaultMilestone();
    }

    get uniquePurchases(): string {
        const res = this.milestone.state
            .find((item) => item.metric === MILESTONE_STATE_METRIC.uniquePurchaserCount);

        if (res) {
            return res.value;
        }

        return '0';
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get total(): string {
        const res = this.milestone.state
            .find((item) => item.metric === MILESTONE_STATE_METRIC.total);

        if (res) {
            return `${this.currencyPrefix} ${(Number(res.value) / 100).toFixed(2)}`;
        }

        return '0';
    }

    get uob(): string {
        const uobMetric = this.stats.find((stat) => stat.metric === RankRequirementManager
            .rankRequirementMetricConstants.uniqueOrderBonusMetric);

        return uobMetric ? uobMetric.value : '0';
    }
}
