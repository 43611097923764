import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { PowerSellerClubType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetPowerSellerClubResultType } from '@/api/graphQL/graphNodes/GetPowerSellerClubQuery';
import powerSellerClubRepository from './services/powerSellerClubRepository';
import { defaultPowerSellerClub } from './defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'powerSellerClub',
})
@AutoMutations
export class PowerSellerClub extends VuexModule {
    private data: PowerSellerClubType = defaultPowerSellerClub();

    private loaded: boolean = false;

    private loading: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setData(val: PowerSellerClubType) {
        this.data = { ...val };
    }

    @Action()
    public async getData() {
        try {
            this.setLoading(true);
            const res: GetPowerSellerClubResultType = await powerSellerClubRepository.getData();

            this.setData(res.profile);

            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(PowerSellerClub);
