import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import componentsControl from '@/modules/ComponentsControls';
import {
    AssetType, BusinessDetailsType, FlagFullType, ProfileType,
    ProfileUpdateType, UploadProfilePictureType,
} from '@/api/graphQL/graphNodes/types';
import { UpdateProfileResultType } from '@/api/graphQL/graphNodes/UpdateProfileQuery';
import { GetProfileResultType, ProfileParamsType } from '@/api/graphQL/graphNodes/GetProfileQuery';
import Vue from 'vue';
import { UPDATED_SUCCESSFULLY } from '@/utils/messages/formValidation';
import { FileType } from '@/components/fileArea/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import profileBusinessAddressSelect from '@/modules/ProfileBusinessAddressSelect';
import profileFormValidation from './services/profileFormValidation';
import { LabelType, ObjectPropertyType } from '../types';
import {
    profile as defaultProfile, defaultProfilePictureData,
    defaultBusinessDetails,
} from './defaults';
import { gbLabels, resolveBankingDetails } from '../labels';
import { FORM_ERROR_TOOLTIP, JOAH_FLAG_KEY } from '../constants';
import ProfileRepository from './services/profileRepository';
import { TERMS_AND_CONDITIONS_TYPE } from './constants';
import { defaultFlag } from '../defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'profile',
})
@AutoMutations
export class Profile extends VuexModule {
    private originalData: ProfileType = defaultProfile();

    private data: ProfileType = defaultProfile();

    private businessDetails: BusinessDetailsType = defaultBusinessDetails();

    private disableBusinessDetails: boolean = false;

    private profilePicture: UploadProfilePictureType = defaultProfilePictureData();

    private shopUrl: string = '';

    private shopLoginUrl: string = '';

    private labels: LabelType = { ...gbLabels() };

    private loading: boolean = false;

    private imageDialogOpen: boolean = false;

    private profilePicUpload: FileType|null = null;

    private cameraDialogOpen: boolean = false;

    private formErrors: ErrorType = {};

    private displayTooltip: boolean = false;

    private vanityUrl: string = '';

    private termsDialogOpen: boolean = false;

    private termsAndConditionsFile: AssetType|null = null;

    private joah: FlagFullType = defaultFlag();

    get getProfileData(): ProfileType {
        return this.data;
    }

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setLabels(payload: LabelType) {
        this.labels = { ...payload };
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setProfileData(data: Partial<ProfileType>) {
        this.data = ObjectProcessor.objectMerge(defaultProfile(), data);
        this.originalData = ObjectProcessor.objectMerge(defaultProfile(), data);
    }

    @Mutation
    public setBusinessDetails(data: BusinessDetailsType) {
        this.businessDetails = { ...defaultBusinessDetails(), ...data };
    }

    @Mutation
    public setDisableBusinessDetails(val: boolean) {
        this.disableBusinessDetails = val;
    }

    @Mutation
    public setFormData(data: Partial<ProfileType>) {
        this.data = { ...this.data, ...data };
    }

    @Mutation
    public setProfilePicture(val: UploadProfilePictureType) {
        this.profilePicture = { ...val };
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public resetDataToOriginal() {
        this.data = { ...this.originalData };
        this.businessDetails = { ...defaultBusinessDetails() };
    }

    @Mutation
    public setShopUrl(val: string) {
        this.shopUrl = val;
    }

    @Mutation
    public setShopLoginUrl(val: string) {
        this.shopLoginUrl = val;
    }

    @Mutation
    public setImageDialogOpen(val: boolean) {
        this.imageDialogOpen = val;
    }

    @Mutation
    public setCameraDialogOpen(val: boolean) {
        this.cameraDialogOpen = val;
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors[payload.key] = payload.val;
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public setVanityUrl(val: string) {
        this.vanityUrl = val;
    }

    @Mutation
    toggleTermsDialogOpen() {
        this.termsDialogOpen = !this.termsDialogOpen;
    }

    @Mutation
    public setTermsAndConditionsFile(val: AssetType|null) {
        this.termsAndConditionsFile = val;
    }

    @Mutation
    public setJoah(val: FlagFullType = defaultFlag()) {
        this.joah = { ...val };
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public validateInvitationForm(payload: {
        data: ProfileUpdateType;
        businessDetails: BusinessDetailsType;
    }) {
        this.clearFormErrors();
        const { data, businessDetails } = payload;
        const formErrors = profileFormValidation(data, businessDetails);
        formErrors.forEach((error) => this.setFormError(error));
    }

    @Action()
    public async getProfile(params: { isImpersonating: boolean, isAmbassador: boolean }) {
        try {
            this.setLoading(true);

            const { isAmbassador } = params;
            const reqParams: ProfileParamsType = {
                type: isAmbassador
                    ? TERMS_AND_CONDITIONS_TYPE.ambassador
                    : TERMS_AND_CONDITIONS_TYPE.hostess,
            };
            const result: GetProfileResultType = await ProfileRepository
                .getProfile(reqParams) as GetProfileResultType;
            const {
                rank, businessDetails, flags, ...profile
            } = result.profile;

            // @ts-ignore
            Vue.$gtag.set({
                Rank: { label: rank.label, id: rank.id },
            });

            // flags
            const indexOfFlag = flags.map((item) => item.key).indexOf(JOAH_FLAG_KEY);

            if (indexOfFlag >= 0) {
                this.setJoah(flags[indexOfFlag]);
            } else {
                this.setJoah();
            }

            this.setVanityUrl(result.details.vanityUrl);

            if (profile.profilePicture !== null) {
                this.setProfilePicture(profile.profilePicture);
            } else {
                this.setProfilePicture(defaultProfilePictureData());
            }

            if (
                businessDetails
                && businessDetails.businessAddress
                && businessDetails.businessName
            ) {
                const formattedBusinessDetails = {
                    ...defaultBusinessDetails(),
                    ...ObjectProcessor.removeEmptyProperties(businessDetails),
                };

                this.setDisableBusinessDetails(true);
                this.setBusinessDetails(formattedBusinessDetails);
            } else {
                this.setDisableBusinessDetails(false);
                this.setBusinessDetails(defaultBusinessDetails());
            }

            if (result.termsAndConditions) {
                this.setTermsAndConditionsFile(result.termsAndConditions.content);
            }

            const formattedData = ObjectProcessor.removeEmptyProperties(profile);
            this.setProfileData(formattedData);

            this.setLabels(resolveBankingDetails(profile.country).labels);
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async updateProfile() {
        try {
            const { businessDetails } = this;

            const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                id, parentId, profilePicture, rank,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                paidForKit, joinDate, kitPurchaseDate,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ...oldData
            } = this.data;

            const data: ProfileUpdateType = {
                ...oldData,
            };

            await this.validateInvitationForm({ data, businessDetails });

            if (Object.keys(this.formErrors).length === 0) {
                this.setLoading(true);
                this.setDisableBusinessDetails(false);

                let businessDetailsData: null|BusinessDetailsType = null;

                if (businessDetails.businessAddress !== '' && businessDetails.businessName !== '') {
                    businessDetailsData = businessDetails;
                }

                const props = {
                    ...data,
                    businessDetails: businessDetailsData,
                    flags: [{
                        key: this.joah.key,
                        value: this.joah.value,
                    }],
                };

                const result: UpdateProfileResultType = await ProfileRepository
                    .updateProfile(props);

                const { flags, ...updateProfile } = result.updateProfile;

                // flags
                const indexOfFlag = flags.map((item) => item.key).indexOf(JOAH_FLAG_KEY);

                if (indexOfFlag >= 0) {
                    this.setJoah(flags[indexOfFlag]);
                } else {
                    this.setJoah();
                }

                const formattedData = ObjectProcessor.removeEmptyProperties(updateProfile);

                const businessDetailsRes = formattedData.businessDetails
                 || defaultBusinessDetails();

                if (businessDetailsRes && businessDetailsRes.businessAddress
                    && businessDetailsRes.businessName) {
                    this.setBusinessDetails(businessDetailsRes);
                    this.setDisableBusinessDetails(true);
                } else {
                    this.setDisableBusinessDetails(false);
                    this.setBusinessDetails(defaultBusinessDetails());
                }

                this.setProfileData(formattedData);
                componentsControl.showSuccessMessage({ message: UPDATED_SUCCESSFULLY });
                profileBusinessAddressSelect.setAddress();
                profileBusinessAddressSelect.clearOptions();
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Profile);
