
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class orderHeaderTitle extends Vue {
    @Prop() private customerFullName!: string;

    @Prop() private currencyPrefix!: string;

    @Prop() private orderDate!: string;

    @Prop({ default: false }) private showCustomerName!: boolean;

    @Prop() private value!: string;

    @Prop() private orderNo!: string;

    @Prop() private referenceId!: string;

    @Prop() private screenType!: string;

    @Prop() private isReturn!: boolean;

    @Prop() private qualificationValue!: string;

    @Prop() private commissionableOrder!: boolean;

    get dateElementClasses() {
        return [
            'pts-layout-item',
            this.showCustomerName ? 'pts-size-20' : 'pts-size-30',
        ];
    }

    get valueElementClasses() {
        return [
            'OrdersListItem__OrderValues',
            'pts-size-25',
        ];
    }

    get numberElementClasses() {
        return [
            'OrdersListItem__OrderNo',
            'pts-layout-item',
            this.showCustomerName ? 'pts-size-25' : 'pts-size-30',
        ];
    }

    get elementClasses() {
        return [
            'OrdersListItem__Header',
            'pts-layout',
            'pts-size-100',
            { OrdersListItem__HeaderWithName: this.showCustomerName },
        ];
    }

    get psClasses() {
        return [
            'OrdersListItem__OrderValueBg',
            'OrdersListItem__OrderValueBgMargin',
            { 'OrdersListItem__OrderValueBg--negative': Number(this.qualificationValue) < 0 },
        ];
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    formattedPs(): string {
        const flv = Number(this.qualificationValue);

        if (flv >= 0) {
            return `+ FLV ${this.format2DecimalPoints(flv)}`;
        }

        return `- FLV ${this.format2DecimalPoints(Math.abs(flv))}`;
    }
}
